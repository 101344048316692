import React, { useEffect } from 'react';
import { Grid, TextField, ButtonGroup, Button, Typography, Box, InputAdornment, FilledInput, Switch, RadioGroup, Radio, Checkbox } from '@material-ui/core';
import { withRouter } from "react-router";
import ReactQuill from 'react-quill';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import '../../form/InputBuilder.scss';
import Tooltip from '@material-ui/core/Tooltip';   
import * as moment from "moment";

import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import TypographyCustom from '../../../ui/typography/Typography';
import SearchBarProductDynamic from '../../../ui/inputs/SearchBarProductDynamic';
import iconSet from '../../../../assets/selection.json'
import IcomoonReact from "icomoon-react";
import SpeechComponent from '../../inputs/SpeechComponent';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HighlightOff from '@material-ui/icons/HighlightOff';


const GridCustom = styled(Grid) `
    fieldset, .MuiSelect-root{
        border: 0.5px solid ${colors.grey.lighter.hue700};
    }
`;
const GridCustomCheckbox = styled(GridCustom) `
    padding: 0 1rem 0 1rem;
`;

const ButtonGroupCustom = styled(ButtonGroup)`
    button{
        background-color: ${colors.white};
        color: ${colors.grey.regular};
        text-transform: inherit;
        padding: 4px 20px;
        &.selected{
            background-color: ${colors.blue.darker.hue300};
            color: ${colors.white};            
        }
    }
`;

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 13px;
        border-radius: 0;
        border: 1px solid ${colors.grey.border};
    }
    & > p{
        margin: 0;
    }
`;
const LinkText = styled(Typography)`
    color: ${colors.blue.darker.hue300} !important;
    cursor: pointer;
    margin-top: 0.5rem;
    text-decoration: underline;
    &:hover{
        color: ${colors.black.regular} !important;
    }
`;
const HelperText = styled(Typography)`
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: soleil, sans-serif;
    font-weight: 400;
    line-height: 1.66;
`;
const SwitchCustom = styled(Switch)`
    .Mui-checked{
        color: ${colors.blue.lighter.hue150};
        & + .MuiSwitch-track{
            background-color: ${colors.blue.darker.hue300};
        }
    }
`;
const ToolbarRightSide = styled(Grid)`
    position: absolute;
    right: 10px;
    width: auto;
    top: 5px;
    display: flex;
    flex-direction: row;
    align-items:center;
    @media screen and (max-width: 1450px){
        right: 4px;
        top: 1px;
    }
`;

const HighlightOffCustom = styled(HighlightOff)`
    &:hover{
        fill: ${colors.red.darker};
    }
`;

function InputBuilder(props) {
    const [buttonGroupValue, setButtonGroupValue]   = React.useState(null);
    const [switchValue, setSwitchValue]             = React.useState(null);
    const [showPassword, setShowPassword]           = React.useState(false);
    const [errorMessage, setErrorMessage]           = React.useState(null);
    const [seeError, setSeeError]                   = React.useState(false);
    const [openMediaPicker, setOpenMediaPicker]     = React.useState(false);
    const [openProductPicker, setOpenProductPicker] = React.useState(false);
    const [quillInfos, setQuillInfos]               = React.useState(null);
    const [quillIndex, setQuillIndex]               = React.useState(null);
    const goTo = route => props.history.push(route);

    const handleMenuItemClick       = (event, value) => setButtonGroupValue(value);
    const handleSwitchChange        = (event) => setSwitchValue(!switchValue);

    // ComponentDidUpdate like
    useEffect(() => {
        if (props.input.type === 'buttonGroup') {
            if (buttonGroupValue === null) {
                setButtonGroupValue(props.value);
            }

            props.handleButtonGroupChange(props.input.stateName, buttonGroupValue);
        }

        if (props.input.type === 'switch') {
            if (switchValue === null) {
                setSwitchValue(false);
            }

            props.handleButtonGroupChange(switchValue);
        }
    }, [buttonGroupValue, switchValue]);

    // ComponentDidMount like
    // useEffect(() => {

    //     let subscription = eventService.get().subscribe((data) => {
    //         setSeeError(true);
    //         if (data && props.input.stateName === data.stateName) {
    //             setErrorMessage(data.errorMessage);
    //             setSeeError(true)
    //         }
    //     });

    //     return () => subscription.unsubscribe();
    // }, [props.value]); 

    const useStyles = makeStyles({
        select: {
            marginTop: 40,
            boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.06), 0px 8px 10px 1px rgba(0,0,0,0.06), 0px 3px 14px 2px rgba(0,0,0,0.06)",
            "& ul": {
                // backgroundColor: 'r',
                padding: 0,

            },
            "& li": {
                fontSize: 14,
                color: colors.blue.darker.hue300
            },
            "& li.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
                backgroundColor: colors.blue.darker.hue300,
                color: colors.white
            },
            "& li.MuiListItem-root:hover": {
                backgroundColor: colors.blue.lighter.hue900,
                // color: colors.white
            },
        },
        inputRoot: {
            minHeight: 51,
            paddingTop: "10px !important",
            paddingBottom: 1,
            paddingRight: 30,
            border: `0.5px solid ${colors.grey.lighter.hue700}`,
            // "& .MuiChip-root": {
            //     marginBottom: "0 !important",
            // },
            "& .MuiInputBase-input": {
                minWidth: "100%",
            }
        },
    });

    const classes = useStyles();
    switch(props.input.type) {
        case 'textarea': 
        case 'textareaSimple':
        case 'text':
            return (
                <GridCustom item xs={props.xs ? props.xs : 12} style={{position: "relative"}}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        variant="outlined"
                        value={props.value ? props.value : ''}
                        helperText={seeError ? errorMessage : null}
                        fullWidth= {props.input.rightButton ? false : true}
                        onChange={evt => {
                            setSeeError(true);
                            props.stateCallback(evt);
                        }}
                        name={props.input.stateName}
                        type={props.input.type}
                        placeholder={props.input.label}
                        disabled={props.input.disabled ? true : false}
                        error={seeError && errorMessage ? true : false}
                        style={{width: props.input.rightButton ? '90%' : '100%'}}
                    />
                    {
                        props.input.rightButton ? 
                        <Tooltip 
                            title={props.value ? props.input.rightButton.textTooltip : props.input.rightButton.textTooltipDisabled}
                        >
                            <Grid 
                            container 
                            alignItems="center"
                            justifyContent="center"
                            style={{width: '10%', height: 51, minWidth: 'auto', padding: 0, position: 'absolute', top: 0, right: 0, cursor: props.value ? 'pointer' : 'inherit'}} 
                            onClick={props.value ? props.input.rightButton.callback : null}
                            >
                                <IcomoonReact 
                                iconSet={iconSet} 
                                color={props.value ? colors.blue.darker.hue300 : colors.grey.lighter.hue600} 
                                size={35} 
                                icon={props.input.rightButton.icon}
                                />
                            </Grid>
                        </Tooltip>
                        : null
                    }

                    {
                        seeError && errorMessage ? null :
                        props.input.helper.link 
                            ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText> 
                            : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    }
                </ GridCustom>
            );
        case 'number': 
        case 'decimal':
            return (
                <GridCustom item xs={props.xs ? props.xs : 12} style={{position: "relative"}}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        variant="outlined"
                        value={props.value ? props.value : ''}
                        helperText={seeError ? errorMessage : null}
                        fullWidth= {props.input.rightButton ? false : true}
                        onChange={evt => {
                            setSeeError(true);
                            props.stateCallback(evt);
                        }}
                        name={props.input.stateName}
                        type={'number'}
                        placeholder={props.input.label}
                        disabled={props.input.disabled ? true : false}
                        error={seeError && errorMessage ? true : false}
                        style={{width: props.input.rightButton ? '90%' : '100%'}}
                    />

                    {
                        seeError && errorMessage ? null :
                        props.input.helper.link 
                            ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText> 
                            : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    }
                </ GridCustom>
            );
        case 'select':
            return (
                <GridCustom item xs={12} style={{position: 'relative'}}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        variant="outlined"
                        color="secondary"
                        select
                        fullWidth
                        SelectProps={{
                            multiple: props.input.multiselect,
                            MenuProps:{
                                elevation:0,
                                PaperProps:{
                                    square:true,
                                    style:{
                                        border: `1px solid ${colors.grey.border}`
                                    }
                                }
                            }
                        }}
                        placeholder={props.input.label}
                        value={props.value ? props.value : ''}
                        onChange={props.stateCallback}
                        helperText={seeError ? errorMessage : null}
                        disabled={props.input.disabled ? true : false}
                        error={seeError && errorMessage ? true : false}
                        >
                        {props.input.value.map((option,index) => {
                            return(
                                <MenuItem style={{color:colors.black.regular}} key={`OptionSelect${index}`} value={option.value} disabled={option.disabled}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </TextFieldCustom>
                    {
                        props.value ? 
                            <HighlightOffCustom 
                                style={{width: 25, cursor: "pointer", position:"absolute",top: "50%", right: 30, transform: 'translateY(-50%)'}} 
                                onClick={() => {
                                    props.stateCallback('')
                                }}
                            />
                        : null
                    }
                    {
                        seeError && errorMessage ? null :
                        props.input.helper.link 
                            ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText> 
                            : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                    }
                </GridCustom>
            );
        case 'selectTree':
            if (!props.input.multiselect){
                return (
                    <GridCustom item xs={12}>
                        <DropdownTreeSelect 
                            id={props.input.stateName}
                            data={props.input.data} 
                            mode="radioSelect"
                            inlineSearchInput={true}
                            className={seeError && errorMessage ? 'error singleSelectTree' : 'singleSelectTree'}
                            disabled={props.input.disabled ? true : false}
                            texts={{ placeholder: 'Rechercher...', noMatches: 'Aucun résultat' }}
                            onChange={(currentNode, selectedNodes) => {
                                setSeeError(true);
                                props.stateCallback(currentNode.value);
                            }}
                        />
                        {
                            !seeError ? props.input.helper.link 
                            ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText> 
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText> 
                            : <span className="error-label">{ errorMessage }</span>
                        }
                    </GridCustom>
                );
            } else {
                return (
                    <GridCustom item xs={12}>
                        <DropdownTreeSelect 
                            className={seeError && errorMessage ? 'error multiSelectTree' : 'multiSelectTree'}
                            id={props.input.stateName}
                            data={props.input.data} 
                            mode="multiSelect"
                            keepOpenOnSelect={true}
                            inlineSearchInput={true}
                            disabled={props.input.disabled ? true : false}
                            texts={{ placeholder: props.input.data.length > 0 ? 'Rechercher...' : 'Chargement...', noMatches: 'Aucun résultat' }}
                            onChange={(currentNode, selectedNodes) => {
                                setSeeError(true);
                                props.stateCallback(selectedNodes);
                            }}
                        />
                        {
                            !seeError ? props.input.helper.link 
                            ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText> 
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText> 
                            : <span className="error-label">{ errorMessage }</span>
                        }
                        {/* { 
                            props.input.showSelected
                                ? props.value 
                                    ? props.value.map((attr,index) =>{
                                        let attrSelected = ''
                                        let firstAttr = false
                                        index > 0 
                                            ? attrSelected = '- ' + attr.label : attrSelected = attr.label; firstAttr = true
                                        return(
                                            <TypographyCustom key={`AttributsSelected${index}`} variant={'body2'} style={{display: 'inline-block'}}>{firstAttr ? <span>{attrSelected}&nbsp;</span> : attrSelected}</TypographyCustom>
                                        )
                                    }) : null
                                : null
                        } */}
                    </GridCustom>
                );
            }
        // case 'textarea': 
        //     const modules = {
        //         toolbar: [
        //             ['bold', 'italic', 'underline'],
        //         ],
        //     };
        //     const formats = [
        //         'bold', 'italic', 'underline',
        //     ];

        //     function insertProduct() {
        //         let editor = quillInfos.getEditor();
        //         const getIndex = editor.selection.savedRange;
        //         setQuillIndex(getIndex)
        //         setOpenProductPicker(true);
        //     }

        //     function getProductSku(value) {
        //         const refQuill = quillInfos.getEditor();
        //         const cursorPosition = quillIndex.index;
        //         const textToInsert = `<product sku="${value.sku}" idProduct="${value.id}"/> `;
        //         refQuill.insertText(cursorPosition, textToInsert);
        //         refQuill.setSelection(cursorPosition + textToInsert.length);
        //     }

        //     if (!window.delays)
        //         window.delays = {};
        //     return (
        //         <GridCustom item xs={12}>
        //             <Drawer transitionDuration={400} anchor="right" open={openProductPicker} onClose={() => setOpenProductPicker(false)} style={{minWidth: 300}}>
        //                 <Grid container style={{width: 300, padding: 20}}>
        //                     <TypographyCustom variant="body2" colortext="#333333">Choisir le produit à importer :</TypographyCustom>
        //                     <SearchBarProductDynamic 
        //                         key={`ProductSelector`}
        //                         allProducts={true}
        //                         isMulti={false}
        //                         currentLang={'fr_FR'}
        //                         style={{
        //                             position: 'relative',
        //                             top: 15,
        //                             marginBottom: 5,
        //                             width: 300
        //                         }}
        //                         onSelect={(value) => {
        //                             setOpenProductPicker(false);
        //                             getProductSku(value);
        //                         }}
        //                     />
        //                 </Grid>
        //             </Drawer>
        //             <div style={{position: 'relative'}}>
        //                 <ToolbarRightSide>
        //                     {props.input.isContent ?
        //                         <Button className="spread-insert-product" style={{width: "auto", minWidth: 'auto'}} onClick={() => insertProduct()}>
        //                             <LocalOfferIcon style={{width: 20, height: 20}}/>
        //                         </Button>
        //                         : null
        //                     }
        //                     <SpeechComponent value={props.value || ""} callback={props.stateCallback}/>
        //                 </ToolbarRightSide>
        //             </div>
        //             <ReactQuill 
        //                 className={seeError && errorMessage ? 'error' : ''}
        //                 value={props.value || ""}
        //                 key={"quill-editor"}
        //                 theme="snow"
        //                 modules={modules}
        //                 formats={formats}
        //                 readOnly={props.input.disabled}
        //                 ref={(el) => { 
        //                     setQuillInfos(el) 
        //                 }}
        //                 onChange={(content, delta, source, editor) => {
        //                     clearTimeout(window.delays[props.input.stateName]);
        //                     window.delays[props.input.stateName] = setTimeout(() => {
        //                         props.stateCallback(content);
        //                         setSeeError(true);
        //                     }, 200);
        //                 }}
        //                 placeholder={props.input.helper.text}
        //             />

        //             {
        //                 !seeError ? null : 
        //                 <span className="error-label">{ errorMessage }</span>
        //             }
        //         </GridCustom>
        //     );
        // case 'textareaSimple': 
        //     if (!window.delays)
        //         window.delays = {};

        //     return (
        //         <GridCustom item xs={12}>
        //             <TextFieldCustom
        //                 id={props.input.stateName}
        //                 variant="outlined"
        //                 value={props.value ? props.value : ''}
        //                 helperText={seeError ? errorMessage : null}
        //                 fullWidth
        //                 multiline
        //                 rows={4}
        //                 onChange={evt => {
        //                     setSeeError(true);
        //                     props.stateCallback(evt);
        //                 }}
        //                 name={props.input.stateName}
        //                 placeholder={props.input.label}
        //                 disabled={props.input.disabled ? true : false}
        //                 error={seeError && errorMessage ? true : false}
        //             />

        //             {
        //                 seeError && errorMessage ? null :
        //                 props.input.helper.link 
        //                     ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText> 
        //                     : 
        //                     <>
        //                     <HelperText variant="body2">
        //                         {props.input.helper.text}
        //                     </HelperText>
        //                     {props.input.helper.charCount ? 
        //                     <HelperText variant="body2">
        //                         {`${props.value ? props.value.length : 0} / ${props.input.helper.maxChar}`}
        //                     </HelperText>
        //                     :null}
        //                     </>
        //             }
        //         </GridCustom>
        //     );
        case 'date': 
            return (
                <GridCustom item xs={12}>
                    <TextFieldCustom
                        id={props.input.stateName}
                        type={props.input.getTime ? "datetime-local" : "date"}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            step: "1",
                            min: props.input.minDate ? props.allState[props.input.minDate] : null
                        }}
                        defaultValue={props.input.getTime ? moment(props.value).format().slice(0,19) : props.value}
                        error={seeError && errorMessage ? true : false}
                        onChange={(evt) => {
                            props.stateCallback(evt);
                            setSeeError(true);
                        }}
                        helperText={seeError && setErrorMessage ? null : props.input.helper.link ? <Box><LinkText variant="h5">{props.input.helper.text}</LinkText></Box> : props.input.helper.text}
                        disabled={props.input.disabled ? true : false}
                    />

                    {
                        !seeError ? null : 
                        <span className="error-label">{ errorMessage }</span>
                    }
                </GridCustom>
            );
        case 'buttonGroup':
            return (
                <GridCustom item xs={12}>
                    <ButtonGroupCustom color="primary" aria-label="outlined primary button group">
                        {props.input.value.map((option, index) => 
                            (
                                <Button 
                                    key={index} 
                                    value={option.value} 
                                    selected={option.value === props.value} 
                                    className={option.value === props.value ? 'selected' : ''} 
                                    onClick={event => handleMenuItemClick(event, option.value)}
                                    disabled={props.input.disabled ? true : false}
                                >
                                    {option.label}
                                </Button>
                            )
                        )}
                    </ButtonGroupCustom>
                </GridCustom>
            );
        case 'switch': 
            return (
                <GridCustom item xs={12}>
                    <SwitchCustom
                        checked={switchValue ? switchValue : false}
                        className={switchValue ? 'checked' : ''}
                        onChange={event => handleSwitchChange(event)}
                        disabled={props.input.disabled ? true : false}
                        color="primary"
                        name="checkedB"
                        // value={switchValue || ''}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </GridCustom>
            );
        default:
            return null;
    }
}

export default withRouter(InputBuilder);