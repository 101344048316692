import React from 'react';
import { connect } from "react-redux";
import {Grid, Badge, Accordion, AccordionSummary, AccordionDetails,} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from '../../../../../js/utils/axios';
import request from '../../../../../js/utils/fetch';
import Typography from '../../../../ui/typography/Typography';
import DialogModal from '../../../../ui/dialog/DialogModal';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../../assets/selection.json";
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import Button from '../../../../ui/button/Button';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../js/constants/alert-types';

const AccordionCustom = styled(Accordion)`
    box-shadow: none;
`;
const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0;
    border-bottom: 1px solid ${colors.grey.lighter.hue900};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;
const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
    margin-top: 8px;
`;

const ContainerBadge = styled(Grid)`
    padding: 20px 0;
`;
const BadgeCustom = styled(Badge)`
    display: block;
    padding: 8px 16px;
    border-radius: 50px;
    font-weight: bold;
    background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
    color: ${props => props.bgcolor ? colors.white : colors.black.regular};
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;
    &.active{
        background-color: ${colors.white};
        color: ${colors.black.regular};
        border: 1px solid ${colors.black.regular};
        path{
            fill: ${colors.black.regular} !important;
        }
    }
`;


class ModalSynchro extends React.Component { 
    constructor(props){
        super(props)
            this.state = {
                modalSynchro: props.modalSynchro,
                expanded: null,
                counterCron: 0,
                selectedCron: null,
                ready: false
            }
    }
    startCron = (type) => {
        this.props.startLoading();
        request(`${process.env.REACT_APP_API}/create_cron/${type}`, 'get', false).then(
            (data) => {
                if (data.success){
                    this.props.snack(ALERT_SUCCESS, data.message);
                    this.props.stopLoading();
                    this.getStatusCron(type);
                }else{
                    this.props.snack(ALERT_ERROR, data.message);
                    this.props.stopLoading();
                    this.getStatusCron(type);
                }
            }
        );
    }

    getStatusCron = (typeCron) => {
        this.setState({
            selectedCron : typeCron,
            ready: false
        },() =>{
            request(`${process.env.REACT_APP_API}/cron/status/${typeCron}`, 'get', false).then(
                (data) => {
                    this.setState({
                        statusCron: data.status,
                        ready: true
                    });
                }
            );
        })
    }

    handleChange = (panel) => (event, newExpanded) => {
        this.setState({
            expanded : newExpanded ? panel : false

        });
    };

    renderSyncButtons(assetType){
        let assetIdentifier = assetType.node.identifier;
        switch(assetType.node.assetType.identifier) {
            case 'magento':
                return(
                    <AccordionCustom expanded={this.state.expanded === "panel1"} onChange={this.handleChange('panel1')}>
                        <AccordionSummaryCustom
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h4" style={{color: colors.blue.lighter.hue800, marginRight: 8}}>
                                Synchronisations Magento
                            </Typography>
                        </AccordionSummaryCustom>
                        <AccordionDetailsCustom>
                            <ContainerBadge container alignItems={"center"} style={{padding: "20px 0"}}>
                                {/* <BadgeCustom className={this.state.selectedCron === `magento-${assetIdentifier}-attributes` ? 'active' : null} onClick={() => this.getStatusCron(`magento-${assetIdentifier}-attributes`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-attribut"} style={{marginRight: 8}}/>Attributs</BadgeCustom> */}
                                {/* <BadgeCustom style={{marginLeft: 8}} className={this.state.selectedCron === `magento-${assetIdentifier}-categories` ? 'active' : null} onClick={() => this.getStatusCron(`magento-${assetIdentifier}-categories`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-categorie"} style={{marginRight: 8}}/>Catégories</BadgeCustom> */}
                                {/* <BadgeCustom style={{marginLeft: 8}} className={this.state.selectedCron === `magento-${assetIdentifier}-products` ? 'active' : null} onClick={() => this.getStatusCron(`magento-${assetIdentifier}-products`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-produit"} style={{marginRight: 8}}/>Produits</BadgeCustom> */}
                                <BadgeCustom style={{marginLeft: 8}} className={this.state.selectedCron === `magento-${assetIdentifier}-medias` ? 'active' : null} onClick={() => this.getStatusCron(`magento-${assetIdentifier}-medias`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"icon-medias"} style={{marginRight: 8}}/>Médias</BadgeCustom>
                                {/* <BadgeCustom style={{marginLeft: 8}} className={this.state.selectedCron === `magento-${assetIdentifier}-pricesAndStocks` ? 'active' : null} onClick={() => this.getStatusCron(`magento-${assetIdentifier}-pricesAndStocks`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-stock"} style={{marginRight: 8}}/>Stock</BadgeCustom> */}
                                <BadgeCustom style={{marginLeft: 8}} className={this.state.selectedCron === `magento-${assetIdentifier}-parents_only` ? 'active' : null} onClick={() => this.getStatusCron(`magento-${assetIdentifier}-parents_only`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-produit"} style={{marginRight: 8}}/>Produits</BadgeCustom>
                                <BadgeCustom style={{marginLeft: 8}} className={this.state.selectedCron === `magento-${assetIdentifier}-merchandising` ? 'active' : null} onClick={() => this.getStatusCron(`magento-${assetIdentifier}-merchandising`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-produit"} style={{marginRight: 8}}/>Merchandising</BadgeCustom>

                            </ContainerBadge>
                        </AccordionDetailsCustom>
                    </AccordionCustom>
                )
            case 'fbshopping':
                return(
                    <AccordionCustom expanded={this.state.expanded === "panel2"} onChange={this.handleChange('panel2')}>
                        <AccordionSummaryCustom
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h4" style={{color: colors.blue.lighter.hue800, marginRight: 8}}>
                                Synchronisations Facebook Shopping
                            </Typography>
                        </AccordionSummaryCustom>
                        <AccordionDetailsCustom>
                            <ContainerBadge container alignItems={"center"} style={{padding: "20px 0"}}>
                                <BadgeCustom className={this.state.selectedCron === `fbshopping-${assetIdentifier}-products` ? 'active' : null} onClick={() => this.getStatusCron(`fbshopping-${assetIdentifier}-products`)} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-produit"} style={{marginRight: 8}}/>Produits</BadgeCustom>
                            </ContainerBadge>
                        </AccordionDetailsCustom>
                    </AccordionCustom>
                )
            // case 'minisite':
            //     return (
            //         <AccordionCustom expanded={this.state.expanded === "panel2"} onChange={this.handleChange('panel2')}>
            //             <AccordionSummaryCustom
            //                 expandIcon={<ExpandMoreIcon/>}
            //                 aria-controls="panel1a-content"
            //                 id="panel1a-header"
            //             >
            //                 <Typography variant="h4" style={{color: colors.blue.lighter.hue800}}>
            //                     Synchronisations Minisite:
            //                 </Typography>
            //             </AccordionSummaryCustom>
            //             <AccordionDetailsCustom>
            //                 <ContainerBadge container alignItems={"center"} style={{marginTop: 16}}>
            //                     <BadgeCustom disabled={false} onClick={() => axios(`${process.env.REACT_APP_API}/create_cron/magento-${assetIdentifier}-attributes`, 'post')} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-attribut"} style={{marginRight: 8}}/>Attributs</BadgeCustom>
            //                     <BadgeCustom style={{marginLeft: 8}} disabled={true} onClick={() => axios(`${process.env.REACT_APP_API}/create_cron/magento-${assetIdentifier}-categories`, 'post')} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-categorie"} style={{marginRight: 8}}/>Catégories</BadgeCustom>
            //                     <BadgeCustom style={{marginLeft: 8}} disabled={false} onClick={() => axios(`${process.env.REACT_APP_API}/create_cron/magento-${assetIdentifier}-products`, 'post')} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-produit"} style={{marginRight: 8}}/>Produits</BadgeCustom>
            //                     <BadgeCustom style={{marginLeft: 8}} disabled={false} onClick={() => axios(`${process.env.REACT_APP_API}/create_cron/magento-${assetIdentifier}-medias`, 'post')} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"icon-medias"} style={{marginRight: 8}}/>Médias</BadgeCustom>
            //                     <BadgeCustom style={{marginLeft: 8}} disabled={false} onClick={() => axios(`${process.env.REACT_APP_API}/create_cron/magento-${assetIdentifier}-pricesAndStocks`, 'post')} bgcolor={colors.black.regular}><IcomoonReact iconSet={iconSet} color={colors.white} size={15} icon={"picto-stock"} style={{marginRight: 8}}/>Stock</BadgeCustom>
            //                 </ContainerBadge>
            //             </AccordionDetailsCustom>
            //         </AccordionCustom>
            //     )
            default: return null;
        }
    }

    renderModal(){
        return(
            <DialogModal
                open={this.props.modalSynchro}
                title={"Gestion des synchronisations"}
                notText={true}
                secondaryborder="none"
                secondaryAction={this.props.onClose}
                onClose={this.props.onClose}
                maxWidth="md"
                windowWidth={this.props.windowWidth}
            >
                {
                    this.props.listAsset.map((asset,index) => {
                        return(
                            <Grid container key={`sync-container-${index}`}>
                                <Grid container alignItems={"center"} style={{display: "block"}}>
                                    {this.renderSyncButtons(asset)}
                                </Grid>
                            </Grid>
                        )
                    })
                }
                <Grid container style={{marginTop: 32}}>
                    {
                        this.state.selectedCron ?
                            this.state.ready ?
                                this.state.statusCron === "no cron" ?
                                    <Grid container style={{background: colors.green.regular, padding: 20, maxWidth: 562}} direction="row" alignItems="center">
                                        <CheckIcon fontSize="default" style={{color: colors.white}}/>
                                        <Typography variant={"h3"} colortext={colors.white} style={{marginLeft: 8}}>Pas de synchronisation en cours</Typography>
                                        <Typography variant={"h4"} colortext={colors.white} style={{width: "100%", fontSize: 14, lineHeight: "16px", marginTop: 8}}>Attention: le lancement rapproché de deux synchronisations peut engendrer des erreurs, veillez aux lancement à intervalle régulier.</Typography>
                                        <Grid container justifyContent="flex-end">
                                            <Button border="1px solid white" bgcolor="transparent" onClick={() => this.startCron(this.state.selectedCron)}>Lancer une synchronisation</Button>
                                        </Grid>
                                    </Grid>
                                    : <Grid container style={{background: colors.red.darker, padding: 20, maxWidth: 562}} direction="row" alignItems="center">
                                            <WarningIcon fontSize="default" style={{color: colors.white}}/>
                                            <Typography variant={"h3"} style={{color: colors.white, marginLeft: 8}}>Synchronisation en cours</Typography>
                                            <Typography variant={"h4"} colortext={colors.white} style={{width: "100%", fontSize: 14, lineHeight: "16px", marginTop: 8}}>Impossible de lancer une nouvelle synchronisation, celui-ci est déjà en cours.</Typography>
                                        </Grid>
                                : <PageLoader style={{height: "100%"}}/>
                        : <Grid container style={{background: colors.grey.regular, padding: 20, maxWidth: 562}} direction="row" alignItems="center">
                                <InfoIcon fontSize="default" style={{color: colors.white}}/>
                                <Typography variant={"h3"} colortext={colors.white} style={{marginLeft: 8}}>Sélectionnez une synchronisation</Typography>
                                <Typography variant={"h4"} colortext={colors.white} style={{width: "100%", fontSize: 14, lineHeight: "16px", marginTop: 8}}>Cliquez sur une synchronisation afin de voir son status ainsi que la possibilité d'en lancer une.</Typography>
                            </Grid>
                    }
                </Grid>
            </DialogModal>
        )
    }

    render(){
        return(this.renderModal());
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default connect(null, mapDispatchToProps)(ModalSynchro);
