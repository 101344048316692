import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';

const useStyles = makeStyles((theme) => ({
  textarea: {
    backgroundColor: '#fff',
    marginBottom: theme.spacing(2),
    width: '100%',
    '& > div':{
      borderRadius: '0!important',
    }
  }
}));

export default function Textarea({ onChange, value, translate }) {
  const classes   = useStyles();
  const [loading, setLoading] = useState(false);
  let timer       = null;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link','video'],
    ],
  };

  const formats = [
    'bold', 'italic', 'underline', 'size', 'list', 'link','video'
  ];

  const input = <ReactQuill 
    value={value}
    theme="snow"
    modules={modules}
    formats={formats}
    className={classes.textarea}
    onChange={(content, delta, source, editor) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
          onChange(content);
        }, 100);
    }}
  />;

  if (!translate || process.env.REACT_APP_ENABLE_TRANSLATION !== 'true')
    return input;

  return (
    <div>
      <div className="tools-row">
        <div className="translate-btn" onClick={async () => {
          setLoading(true);

          const key = process.env.REACT_APP_TRANSLATION_KEY;
          const target = 'en';
          const q = encodeURI(value);

          try {
            let response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${key}&target=${target}&q=${q}`);

            response = await response.json();
  
            let translation = response?.data?.translations?.[0]?.translatedText;
  
            if (translation)
              onChange(translation);
          } catch(e) {
            console.log(e?.message ?? e);
          } finally {
            setLoading(false);
          }
        }}>
          <img src="/img/flag-en.png" className={`${loading ? 'rotate' : ''}`} />
        </div>
      </div>
      
      { input }
    </div>
  );
}
