import React, { useEffect, useState } from 'react';
import { Grid, Box, FormControl, MenuItem, Select, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import moment from 'moment';
import OurButton from '../../../ui/button/Button';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import Typography from '../../../ui/typography/Typography';
import request from '../../../../js/utils/fetch';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { withTranslation } from 'react-i18next';
import { SET_GUIDELINE, SET_USER, SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import LineCharts from '../../../layouts/Stats/LineCharts';
import BarCharts from '../../../layouts/Stats/BarCharts';
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../../assets/selection.json";
import ImageFlux from '../../../../../src/assets/images/dashboard/dashboard_flux_de_travail.png';
import { ROUTE_MEDIAS_LIST, ROUTE_PRODUCTS_LIST } from '../../../../js/constants/route-names';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePickerCustom } from '../stats/components/styledComponents';
import MomentUtils from '@date-io/moment';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const TitleGlobals = styled(Typography)`
    line-height: 23px; 
    white-space: nowrap;
    color:${colors.black.regular};
    @media screen and (max-width: 1249px){
        max-width: 100%;
    }
`;
const WrapperGlobalsLeft = styled(Grid)`
    width: 100% ;    
    padding-right:20px;
    @media screen and (max-width: 1249px){
        max-width: 100%
    }
`;

const WrapperGlobalsRight = styled(Grid)`
    width: 100%;    
    @media screen and (max-width: 1249px){
        margin-top: 24px;
        max-width: 100%
    }
`;

const inputStyles = makeStyles({
    input: {
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        minWidth: '200px',
    }
})

const CustomLegend = styled(Box)`
    height:4px;
    width:36px;
    background-color: ${props => props.backgroundColor};
`;

const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;


const HistoryDashboard = (props) => {
    const [periode, setPeriode] = useState('week')
    const [date, setDate] = useState(null)
    const [inputDateLine, setInputDateLine] = useState({
        startDate: moment().startOf('week').format('yyyy-MM-DD'),
        endDate: moment().endOf('week').format('yyyy-MM-DD')
    })
    const [isInputValuesLine, setIsInputValuesLine] = useState(false)
    
    const [periodeBar, setPeriodeBar] = useState('week')
    const [dateBar, setDateBar] = useState(null)    

    const [stats, setStats] = useState([])
    const [statsAreLoading, setStatsAreLoading] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])
    const [totalImported, setTotalImported] = useState(null)

    const [isLineChartsLoaded, setIsLineChartsLoaded] = useState(false)
    const [isBarChartsLoaded, setIsBarChartsLoaded] = useState(false)
    const [error, setError] = useState(null)

    const [lastDatas, setLastDatas] = useState([])

    const inputClass = inputStyles()

    const getChartsStats = (statsDate) => {
        var postData = {
            startDate: statsDate.startDate,
            endDate:statsDate.endDate
        }
        
        setIsLineChartsLoaded(false)
        setStatsAreLoading(true)
        
        request(`${process.env.REACT_APP_API}/scheduler/crons/stats-detailed`, 'POST', postData)
            .then(
                (data) => {                    
                    if (data.success) {                        
                        if (data.stats) {
                            var productStats = [];
                            var dateFormatted = [];
                            for (const key in data.stats) {
                                let date = moment(key).format("ddd D.MM")
                                dateFormatted.push(date)
                                productStats.push(data.stats[key].product.totalImported)
                            }
                            const sortedDataStats = Object.keys(data.stats).sort()                        
                            if (!isInputValuesLine) {                            
                                const diff = moment(postData.endDate).diff(sortedDataStats[sortedDataStats.length - 1], 'days')                        
                                for (let i = 0; i < diff; i++) {
                                    const date = moment(sortedDataStats[sortedDataStats.length - 1]).add(i + 1, 'days').format('ddd DD.MM');
                                    dateFormatted.push(date)
                                }                            
                            }
                            setFormattedDates(dateFormatted)
                            setStatsAreLoading(false)
    
                            setStats({
                                areStatsLoaded: true,
                                productsCron: [{
                                    data: productStats,
                                    title: 'Nombre d\'import',
                                    color: colors.blue.darker.hue300,
                                }]
                            })   
                        }else{
                            setIsLineChartsLoaded(true)
                            setStatsAreLoading(false)
                            setError(true)            
                        }
                        setIsLineChartsLoaded(true)
                    }
                }
            )
            .catch((err)=>{
                setIsLineChartsLoaded(true)
                setStatsAreLoading(false)
                setError(err)            
            })        
    }

    const getBarChartStats = (statsDate) => {
        var postData = {
            startDate: statsDate.startDate
        }        
        setIsBarChartsLoaded(false)        
            request(`${process.env.REACT_APP_API}/scheduler/crons/stats-global`, 'POST', postData)
            .then(
                (data) => {
                    if (data.success) {                        
                        var totalCountProduct = data.stats?.totalImportedProduct;
                        setTotalImported([{
                            data: [totalCountProduct],
                            title: 'Produits',
                            color: colors.blue.darker.hue300,
                        }])
                        setIsBarChartsLoaded(true)
                    }
                }
            )
            .catch((err)=>{
                setIsBarChartsLoaded(true)
                setError(err)            
            })        
    }

    useEffect(() => {
        checkRouting(props)
        handleChangeLineChartsPeriode({ target: { value: "week" } })
        handleChangeBarChartsPeriode({ target: { value: "week" } })
    }, [])

    useEffect(() => {        
        if (date !== null) {
            getChartsStats(date)
        }        
    }, [date])


    useEffect(() => {
        if (date !== null) {
            getBarChartStats(dateBar)
        }
    }, [dateBar])

    useEffect(() => {
        let tempData = []
        try {
            request(`${process.env.REACT_APP_API}/dashboards/stats/media`, 'GET', null).then(
                (data) => {                    
                    if (data.success) {
                        tempData.push({
                            'icon': 'icon-medias',
                            'data': data.datas.nbContents.toLocaleString(),
                            'dataColor': colors.blue.regularv2,
                            'title': `${data.datas.nbContents.toLocaleString()} Médias`,
                            'moreInfos': `Dernier upload: ${data.datas?.mediaLastAddition?.date ? moment(data.datas.mediaLastAddition.date).format("d/M/Y") : 'N/A'}`
                        })
                    }
                }
            )
        } catch (e) {
            console.log(e);
        }

        try {
            request(`${process.env.REACT_APP_API}/dashboards/stats/product`, 'GET', null).then(
                (data) => {                    
                    if (data.success) {
                        tempData.push({
                            'icon': 'picto-produit',
                            'data': data.datas.nbProducts.toLocaleString(),
                            'dataColor': colors.blue.darker.hue300,
                            'title': `${data.datas.nbProducts.toLocaleString()} Produits`,
                            'moreInfos': `Dernier upload: ${data.datas?.productLastAddition ? moment(data.datas.productLastAddition).format("d/M/Y") : 'N/A'}`
                        })
                    }
                }
            )
        } catch (e) {
            console.log(e);
        }

        setLastDatas(tempData)
    }, [])


    const checkDateOnInput = (date, value, ref) => {
        setIsInputValuesLine(true)
        if (ref === 'start') {
            setInputDateLine({
                startDate: value,
                endDate: moment().format('yyyy-MM-DD')
            })
        } else if (ref === 'end') {
            setInputDateLine({
                startDate: inputDateLine.startDate,
                endDate: value
            })
        }
    }

    const handleInputDate = () => {
        setIsInputValuesLine(true)
        let checkEndDate = null
        if (inputDateLine.endDate > moment().format("yyyy-MM-DD")) {
            checkEndDate=moment().format("yyyy-MM-DD")    
            setInputDateLine({
                startDate: inputDateLine.startDate,
                endDate: checkEndDate
            })        
        }       
        setDate({
            startDate: inputDateLine.startDate,
            endDate: inputDateLine.endDate
        })
    }

    const handleChangeBarChartsPeriode = (e) => {        
        let startDate, endDate;  

        switch (e.target.value) {
            case "week":
                startDate = moment().startOf('week').format('yyyy-MM-DD');
                endDate =  moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
                break;

            case "month":
                startDate = moment().startOf('months').format('yyyy-MM-DD');
                endDate =  moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
                break;

            case "year":
                startDate = moment().startOf('years').format('yyyy-MM-DD');
                endDate =  moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
                break;

            default:
                startDate = moment().startOf('week').format('yyyy-MM-DD');
                endDate = moment().format('yyyy-MM-DD')
                break;
        }

        setDateBar({
            startDate,
            endDate
        })
        setPeriodeBar(e.target.value)
    }

    const handleChangeLineChartsPeriode = (e) => {
        setIsInputValuesLine(false)
        let startDate, endDate;  

        switch (e.target.value) {
            case "week":
                startDate = moment().startOf('week').format('yyyy-MM-DD');
                endDate =  moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
                break;

            case "month":
                startDate = moment().startOf('months').format('yyyy-MM-DD');
                endDate =  moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
                break;

            case "year":
                startDate = moment().startOf('years').format('yyyy-MM-DD');
                endDate =  moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
                break;

            default:
                startDate = moment().startOf('week').format('yyyy-MM-DD');
                endDate = moment().format('yyyy-MM-DD')
                break;
        }

        setDate({
            startDate,
            endDate
        })
        setInputDateLine({
            startDate,
            endDate: moment(endDate).endOf(e.target.value).format('yyyy-MM-DD')
        })
        setPeriode(e.target.value)
    }


    return (
        <div style={{ width: "100%" }}>

            <TopPanel
                title={props.t('drawer.history_imports')}
                subtitle={"Vue d'ensemble des imports de la plateforme"}
                locales={props.locales}
                hasBorder={true}
            />
            <Grid container>
                <Grid container alignItems='center' justifyContent='space-between' style={{ margin: "auto", padding: "0 80px" }}>
                    <Grid item xs={8} md={5}>
                        <Typography variant={"h3"} style={{ fontSize: 25, marginBottom: 18 }}>Comment gérer vos imports</Typography>
                        <Typography variant={"h4"} style={{ lineHeight: "20px" }}>
                            Vous êtes en difficultés pour importer des produits ou des médias ? Rendez-vous sur notre outil de support ; vous pouvez consulter notre documentation ou prendre contact avec l’un de nos experts qui vous répondra dans le meilleur délai.
                        </Typography>
                        <Box style={{ display: "flex", gap: 16, marginTop: "18px" }}>
                            <OurButton
                                border={`1px solid ${colors.blue.darker.hue300}`}
                                onClick={() => props.history.push(ROUTE_MEDIAS_LIST)}
                            >
                                Importer des médias
                            </OurButton>
                            <OurButton
                                bgcolor={colors.blue.lighter.hue900}
                                bgcolorhover={colors.blue.lighter.hue600}
                                color={colors.blue.darker.hue300}
                                border={`1px solid ${colors.blue.darker.hue300}`}
                                onClick={() => props.history.push(ROUTE_PRODUCTS_LIST)}
                            >
                                Importer des produits
                            </OurButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} direction={'row'} >
                        { lastDatas.length > 0 ?
                            lastDatas?.map((data, i) => {
                                return (
                                    <Grid key={i} item md={12} xs={6} style={{ marginBottom: 20 }}>
                                        <Grid container alignItems="center" direction={props.windowWidth > 1400 || props.windowWidth < 1250 && props.windowWidth > 800 ? 'row' : 'column'} style={{
                                            height: "100%",
                                        }}>
                                            {/* Stats */}
                                            <Grid xs item style={{ maxWidth: 80, width: "100%" }}>
                                                <BoxCustom position="relative" display="flex" justifyContent={"center"} statuscolor={data.dataColor} width="100%">
                                                    <CircularProgress variant="determinate" thickness={2.5} value={40} style={{ width: "100%", height: "auto", position: "absolute", left: 0, top: 0, zIndex: 2 }} />
                                                    <CircularProgress
                                                        variant="determinate"
                                                        value={100}
                                                        disableShrink
                                                        style={{ width: "100%", height: "auto" }}
                                                        thickness={2.5}
                                                    />
                                                    <Box
                                                        top={0}
                                                        left={0}
                                                        bottom={0}
                                                        right={0}
                                                        position="absolute"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <IcomoonReact iconSet={iconSet} color={data.dataColor} size={30 } icon={data.icon} />
                                                    </Box>
                                                </BoxCustom>
                                            </Grid>
                                            <Grid xs item style={{
                                                flex: 1,
                                                paddingLeft: 22,
                                            }}>
                                                {/* Texts */}
                                                <Grid container direction="column" alignItems="start" style={{
                                                    textAlign: props.windowWidth > 1400 || props.windowWidth < 1250 && props.windowWidth > 800 ? 'start' : 'center'
                                                }}>
                                                    <Typography style={{ color: colors.black.regular, fontSize: '1.6vw', margin: "0", lineHeight: "1.6vw", fontWeight: '700' }}>{data.title}</Typography>
                                                    <Typography style={{ color: colors.black.regular, fontSize: 12 }}>{data.moreInfos}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                )
                            })
                            :
                            <Grid container md={12} xs={6} style={{ marginBottom: 20 }} justifyContent='center'>
                                <CircularProgress size={'85px'} color={"inherit"}/>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={4} md={3} >
                        <img style={{ maxWidth: "100%" }} src={ImageFlux} />
                    </Grid>
                </Grid>
                <AccordionCustom defaultExpanded={true} square={true} title={"Statistiques d'imports"}>
                    <Grid container direction="column" justifyContent="center" spacing={0}>
                        <Grid container direction="row" alignItems='end' spacing={5} justifyContent='space-between'>
                            {
                                statsAreLoading?
                                <PageLoader/>
                                : 
                                stats.productsCron && !error ?
                                <>
                            {/* Top */}
                                    <WrapperGlobalsLeft item xl={8}>
                                        <Grid container alignItems={'center'} justifyContent={"space-between"} style={{ display: 'flex', gap: 30, rowGap: 0 }}>
                                            <Grid item xs={"auto"}>
                                                <TitleGlobals style={{maxWidth:"none"}} variant={"h2"}>Nombre d'imports</TitleGlobals>
                                            </Grid>
                                            <Grid item xs={"auto"} pt={3} pb={3} style={{ display: 'flex', gap: '10px' }}>
                                                <FormControl>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={periode}
                                                        className={inputClass.input}
                                                        style={{
                                                            width: "100%",
                                                            padding: "5px 10px",
                                                            fontWeight: 'bold'
                                                        }}
                                                        disableUnderline
                                                        onChange={handleChangeLineChartsPeriode}
                                                    >
                                                        <MenuItem value={"week"}>Semaine</MenuItem>
                                                        <MenuItem value={"month"}>Mois</MenuItem>
                                                        <MenuItem value={"year"}>Année</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <MuiPickersUtilsProvider utils={MomentUtils} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: "center" }}>
                                                    <DatePickerCustom
                                                        disableToolbar
                                                        autoOk
                                                        invalidDateMessage={''}
                                                        maxDateMessage={''}
                                                        variant="inline"
                                                        labelFunc={(date, invalidLabel) => moment(date).format("DD/MM/YYYY")}
                                                        format="yyyy-MM-DD"
                                                        value={inputDateLine.startDate}
                                                        maxDate={inputDateLine.endDate}
                                                        onChange={(date, value) => checkDateOnInput(date, value, 'start')}
                                                        onKeyDown={(e) => e.preventDefault()}                                                    
                                                        InputProps={{
                                                            disableUnderline: true
                                                        }}
                                                    />
                                                    <DatePickerCustom
                                                        disableToolbar
                                                        autoOk
                                                        invalidDateMessage={''}
                                                        maxDateMessage={''}
                                                        variant="inline"
                                                        labelFunc={(date, invalidLabel) => moment(date).format("DD/MM/YYYY")}
                                                        format="yyyy-MM-DD"
                                                        value={inputDateLine.endDate}
                                                        maxDate={moment().format('yyyy-MM-DD')}
                                                        minDate={inputDateLine.startDate}
                                                        onChange={(date, value) => checkDateOnInput(date, value, 'end')}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        disabled={true}
                                                        InputProps={{
                                                            disableUnderline: true
                                                        }}
                                                    />
                                                    <OurButton
                                                        onClick={handleInputDate}
                                                        style={{ marginTop: 0, marginBottom: 0 }}
                                                    >
                                                        Valider
                                                    </OurButton>
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            {/* <Box py={3} >
                                                <FormControl>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={periode}
                                                        className={inputClass.input}
                                                        style={{
                                                            width: "100%",
                                                            padding: "5px 10px"
                                                        }}
                                                        disableUnderline
                                                        onChange={handleChangeLineChartsPeriode}
                                                    >
                                                        <MenuItem value={"week"}>Semaine - ({moment().subtract(7, 'days').format('DD.MM.YYYY')} - {moment().format('DD.MM.YYYY')})</MenuItem>
                                                        <MenuItem value={"month"}>Mois - ({moment().subtract(1, 'months').format('DD.MM.YYYY')} - {moment().format('DD.MM.YYYY')})</MenuItem>
                                                        <MenuItem value={"year"}>Année - ({moment().subtract(1, 'years').format('DD.MM.YYYY')} - {moment().format('DD.MM.YYYY')})</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box> */}
                                            <Box style={{ display: "flex", gap: 55,justifyContent:"start",width:"100%",paddingTop:20 }} >
                                                <Box style={{ display: "flex", gap: 8, alignItems: "center" }}>
                                                    <CustomLegend backgroundColor={colors.blue.darker.hue300} />
                                                    <Typography variant={"body1"} style={{ color: colors.black.regular }}>Imports produits</Typography>
                                                </Box>
                                                <Box style={{ display: "flex", gap: 8, alignItems: "center" }}>
                                                    <CustomLegend backgroundColor={colors.blue.regularv2} />
                                                    <Typography variant={"body1"} style={{ color: colors.black.regular }}>Imports médias</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        {isLineChartsLoaded ? (
                                            <>

                                                <Grid container direction="row" justifyContent='space-between'>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <LineCharts xAxisData={formattedDates} yAxisData={stats.productsCron} noLegend={true} fullHeight={true} showArea={false} noExport={true} fullWidth={true}/>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : <PageLoader />}
                                    </WrapperGlobalsLeft>
                                        
                                    <WrapperGlobalsRight item xl={4}>                                                                        
                                        <Box display={'flex'} style={{alignItems:"center",gap:20, justifyContent:"space-between"}}>
                                            <TitleGlobals variant={"h2"}>Nombre d’imports total</TitleGlobals>
                                            <FormControl>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={periodeBar}
                                                    className={inputClass.input}
                                                    style={{
                                                        width: "100%",
                                                        padding: "5px 10px",
                                                        fontWeight: 'bold'
                                                    }}
                                                    disableUnderline
                                                    onChange={handleChangeBarChartsPeriode}
                                                >
                                                    <MenuItem value={"week"}>Semaine en cours</MenuItem>
                                                    <MenuItem value={"month"}>Mois en cours</MenuItem>
                                                    <MenuItem value={"year"}>Année en cours</MenuItem>
                                                </Select>
                                            </FormControl>  
                                        </Box>                                                                            
                                        {
                                            isBarChartsLoaded ?
                                            <Box style={{marginTop:18}}>
                                                <BarCharts xAxisData={["produits", "medias"]} yAxisData={totalImported} fullHeight={true} fullWidth={true}/>
                                            </Box>
                                            :<PageLoader />
                                        }                                                                            
                                    </WrapperGlobalsRight>
                                </>
                                :<Box style={{
                                    display:"flex",
                                    width:"100%",
                                    justifyContent:"center"
                                }}>
                                    <Typography style={{ padding: '24px 0px', color: colors.grey.regular }}>Aucune donnée disponible</Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </AccordionCustom>
            </Grid>
            
        </div>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        setUser: user => dispatch({ type: SET_USER, payload: { user } }),
        setGuideline: guideline => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),

    }
}

export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(HistoryDashboard)));