import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import { withApollo } from 'react-apollo';
import notFound from '../../../../assets/images/not-found.png';
import Chip from '@material-ui/core/Chip';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import styled from 'styled-components';
import { getFileConfig } from '../../../../js/helpers/files';
import { DOCUMENTS, VIDEOS } from '../../../../js/constants/medias-types';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const WrapperName = styled(Box)`
    width: 120px;
    height: 30px;
    overflow: hidden;
    text-align: center;
    overflow-wrap: break-word;
`
const CustomChip = withStyles((theme) => ({
    label: {
        textOverflow: "ellipsis",
        maxWidth: 116
    }
}))(Chip);

const VideoPlaceholder = styled.video`
    position:absolute;
    top:50%;
    left:50%;
    height:90%;
    width:auto;
    max-width:100%;
    transform : translate(-50%,-50%);
`;

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '120',
        height: 120,
        margin: 'auto',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        // marginRight: 15
    },
    selected: {
        border: '2px solid #4caf50'
    },
    selectedRed: {
        border: '2px solid #e00000'
    },
    check: {
        position: 'absolute',
        bottom: 5,
        right: 5,
        color: '#4caf50'
    },
    chip: {
        position: 'absolute',
        top: 10,
        left: 10
    }
}));

function ItemCard ({ style, selected, setSelected, item, product, simpleProduct, onRemove, xs, allowedTypes }) {
    const classes = useStyles();

    const [collectedProps, drag] = useDrag({
        item: { id: product ? 'product' : 'media', type: product ? 'product' : 'media', media: item }
    });

    let isSelected = product 
        ? selected && selected.id === item.id
        : selected && selected.id === item.node.id;

    let name    = null;
    let image   = null;

    // todo: handle translations / or use new product selector

    if (product) {
        if (simpleProduct) {
            let allNames    = item.attributes.product_name;
            name            = allNames?.['fr_FR'] ?? item.sku;

            let allImages   = item.attributes.product_image;
            image           = allImages?.['fr_FR'] ?? null;
        } else {
            const datas = item.productDatas.edges;

            let allNames    = datas.filter(e => e.node.attribute.identifier === 'product_name');
            name            = allNames[0];
        
            let allImages   = datas.filter(e => e.node.attribute.identifier === 'product_image');
            image           = allImages[0];
        }
    }
    return (
        <Grid 
            item 
            xs={xs}
            className={classes.root}
            key={`media-${product ? item.id : item.node.id}`}
            ref={drag}
        >
            <Paper 
                className={`${classes.paper} ${isSelected ? product ? classes.selectedRed : classes.selected : ''}`} 
                style={{
                    ...style,
                    width: isSelected ? 154 - 4 : 154,
                    height: isSelected ? 154 - 4 : 154,
                    backgroundImage: 
                        DOCUMENTS.includes(item.node?.type) 
                            ? `url(${getFileConfig(item.node.type).image})`
                            : product 
                                ? simpleProduct
                                    ? `url('${process.env.REACT_APP_MEDIAS}/${image}')`
                                    : image?.node.media?.filePath ? `url('${process.env.REACT_APP_MEDIAS}/${image.node.media.filePath}')` : `url('${notFound}')`
                                : item?.node.filePath ? `url('${process.env.REACT_APP_MEDIAS}/${item.node.filePath}')` : `url('${notFound}')`,
                    backgroundSize: allowedTypes?.includes(item.node?.type) ? '80%' : "100%"
                }} 
                onClick={() => {
                    setSelected(isSelected ? null : product ? item : item.node);
                }}
            >
                {
                            item !== undefined?
                            item.node.type === 'video/mpeg' ||item.node.type === 'video/webm'|| item.node.type === 'video/mp4'?
                                <VideoPlaceholder>
                                    <source src={process.env.REACT_APP_API_ROOT + '/medias/' + item.node.filePath} type={item.node.type}/>
                                </VideoPlaceholder>
                            :null:null
                        }
                { 
                    isSelected 
                        ? product 
                            ? <Tooltip title="Supprimer" aria-label="delete">
                                <CancelIcon className={classes.check} style={{ color: '#e00000' }} fontSize="large" onClick={() => {
                                    if (onRemove)
                                        onRemove(item);
                                }} /> 
                            </Tooltip>
                            : <CheckCircleIcon className={classes.check} fontSize="large" />
                        : null
                }

                { 
                    product ? (
                        <CustomChip size="small" label={ simpleProduct ? name : name ? name.node.value : item.sku } className={classes.chip} style={{ 
                            top: isSelected ? 8 : 10,
                            left: isSelected ? 8 : 10
                        }} />
                    ) : null
                }
            </Paper>

            {
                product ? null : (
                    <Grid container justifyContent="center">
                        <WrapperName mt={1} style={{
                            width: 120,
                            height: 30,
                            overflow: 'hidden',
                            textAlign: 'center',
                            overflowrap: 'break-word'
                        }}>
                            <Typography variant="h5" component="div">
                                <ResponsiveEllipsis
                                    unsafeHTML={item.node.name ? item.node.name : item.node.filePath}
                                    maxLine={2}
                                    ellipsis='...'
                                    basedOn='letters'
                                />
                            </Typography>
                        </WrapperName>
                    </Grid>
                )
            }
        </Grid>
    );
}

export default withApollo(ItemCard);