import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE } from '../../../../js/constants/constant-rights';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import merchandisingEdit from './config/merchandising/merchandisingEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import { listSettings, listMappers, perPageOptions } from './config/merchandising/listMerchandising.config';
import Listing from '../../../layouts/Listing/Listing';
import * as moment from "moment";
// import { DELETE_USERGROUP } from '../../../../queries/user_groups';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import { withApollo } from 'react-apollo';
import { GET_ASSET_BY_ID_NAVIGATION, GET_ASSET_BY_ID_ONLY_CATALOG } from '../../../../queries/assets';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import CardMerchandisingMain from '../../../layouts/Card/cardContent/CardMerchandisingMain';
import CardCustom from '../../../layouts/Card/CardCustom';
import { checkRouting } from '../../../../js/utils/checkRouting';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import styled from 'styled-components';
import DialogModal from '../../../ui/dialog/DialogModal';

const LayoutWrapper = styled(Box)`
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: calc(100% - 64px);
    position: relative;

    & .layout-wrapper{
        display: none;
    }
`

function EngineAssetsDetailsMerchandising(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [loadedMerch, setLoadedMerch] = React.useState(false);
    const [loadedCat, setLoadedCat] = React.useState(false);
    const [editType, setEditType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [reloadGroupUsers, setReloadMerchandising] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [firstCategories, setFirstCategories] = React.useState([]);
    const [listIdMerchandising, setListIdMerchandising] = React.useState([]);
    const [states, setStates] = React.useState({
        id: null,
        merchandisingCategory: null,
        merchandisingLibelle: null,
        merchandisingStartAt: null,
        merchandisingEndAt: null,
        merchandisingStatus: false,
    });

    useEffect(() => {
        checkRouting(props)
        initCategories()
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            initNavAsset()
        }
    }, [])

    const initNavAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    const goTo = route => {
        props.history.push(route);
    };

    const initCategories = () => {
        setLoadedMerch(false)
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_ONLY_CATALOG,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                setFirstCategories(result.data.asset.catalog.categories[0].children);
                // let listIdMerch = [];
                // for (let category of result.data.asset.catalog.categories[0].children){
                //     if (category.merchandisings.edges.length > 0){
                //         for (let merch of category.merchandisings.edges){
                //             listIdMerch.push(merch.node.id.replace('/api/merchandisings/', ''))
                //         }
                //     }
                // }
                // setListIdMerchandising(listIdMerch)
                setLoadedMerch(true)
                setLoadedCat(true)
                window.setAssetNameMenu(result.data.asset)
            })
            resolve()
        })
    }



    const handleToggleDrawer = (stateDrawer, reset) => {
        setOpenForm(!openForm)
        if (reset) {
            resetStates()
        }
    };
    const handleToggleDialog = (stateDrawer) => {
        setOpenDialog(!openDialog)
    };

    const resetStates = () => {
        setStates({
            id: null,
            merchandisingCategory: null,
            merchandisingLibelle: null,
            merchandisingStartAt: null,
            merchandisingEndAt: null,
            merchandisingStatus: true,
        })
    }

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    // const editHandler=(merchandising)=>{
    //     setEditType('edit')
    //     let getState = {...states};
    //     getState['id'] =  merchandising.id;
    //     getState['merchandisingCategory'] =  merchandising.category.id;
    //     getState['merchandisingLibelle'] =  merchandising.title;
    //     getState['merchandisingStartAt'] =  merchandising.startAt;
    //     getState['merchandisingEndAt'] =  merchandising.endAt;
    //     getState['merchandisingStatus'] =  merchandising.status;
    //     setStates(getState);
    //     handleToggleDrawer('openForm');
    // }
    const initForm = () => {
        setEditType('add')
        handleToggleDrawer('openForm');
    }

    const deleteMutation = async () => {
        props.startLoading();
        // props.client.mutate({
        //     mutation: DELETE_USERGROUP,
        //     variables: { id: states.id }
        // }).then(result => {
        //     props.stopLoading();
        //     props.snack(ALERT_SUCCESS, 'Groupe supprimé avec succès');
        //     setReloadMerchandising(true);
        //     handleToggleDialog();
        //     handleToggleDrawer('openForm', true);
        // }).catch(error => {
        //     props.stopLoading();
        //     props.snack(ALERT_ERROR, `Impossible de supprimer le groupe`);
        //     setReloadMerchandising(true);
        //     handleToggleDialog();
        //     handleToggleDrawer('openForm', true);
        // });
    }

    const handlerMutation = async () => {
        let variables = null;
        if (editType === "add") {
            variables = {
                category: states.merchandisingCategory,
                title: states.merchandisingLibelle,
                startAt: states.merchandisingStartAt,
                endAt: states.merchandisingEndAt,
                status: states.merchandisingStatus,
                asset: `/api/assets/${props.match.params.id}`
            }
            try {
                let createElement = await saveElement('merchandising', variables, { enableLoad: true })
                props.snack(ALERT_SUCCESS, 'Merchandising ajouté avec succès');
                if (createElement) {
                    handleToggleDrawer('openForm', true);
                    initCategories()
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du merchandising');
            }
        } else {
            variables = {
                id: states.id,
                category: states.merchandisingCategory,
                title: states.merchandisingLibelle,
                startAt: states.merchandisingStartAt,
                endAt: states.merchandisingEndAt,
                status: states.merchandisingStatus,
                asset: `/api/assets/${props.match.params.id}`
            }
            try {
                let updateElementResult = await updateElement(states, 'merchandising', variables, null, { enableLoad: true })
                props.snack(ALERT_SUCCESS, 'Merchandising modifié avec succès');
                if (updateElementResult) {
                    handleToggleDrawer('openForm', true);
                    initCategories()
                }
            } catch (e) {
                console.log('e', e)
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification du merchandising');
            }
        }
        // props.stopLoading();
    }

    return (
        <LayoutWrapper>
            <TopPanel
                icomoon={"ico-merchandising"}
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("spread.merchandising.title")}
                subtitle={props.t("spread.merchandising.subtitle")}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                openForm={openForm}
                currentLang={currentLang}
                handleLang={handleLang}
                locales={props.locales}
                handlerAdd={() => initForm()}
                textAdd={props.t("spread.merchandising.textAdd")}
                hasBorder={true}
                buttonAvailable={loadedCat}
            />
            <>
                <Box style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                }}>
                    {
                        loadedMerch ?
                            <Grid container style={{ marginTop: 16 }}>                            
                                {
                                    firstCategories.length > 0 ?
                                        <Grid container spacing={2}>
                                            {
                                                firstCategories.map((catMerch, index) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            key={`list${catMerch.libelle}-${index}`}
                                                            xs={12 / 2}
                                                            sm={12 / 3}
                                                            lg={12 / 3}
                                                        >
                                                            <Box>
                                                                <CardCustom
                                                                    style={{ width: "100%", height: "100%", padding: 0 }}
                                                                    cardContentStyle={{ padding: 0 }}
                                                                >
                                                                    <CardMerchandisingMain data={catMerch} currentLang={currentLang} {...props} />
                                                                    {/* <settings.components data={item} {...props.cardProps} {...propsToPass} currentLang={currentLang} /> */}
                                                                </CardCustom>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        : (
                                            <EmptyCard title={props.t("spread.active_assets.merchNotConfigured")} subtitle={props.t("spread.active_assets.clickToAdd")} textButton={props.t("spread.active_assets.addMerch")} onClick={() => initForm()} picto={EmptyAsset} openForm={openForm} xsImg={openForm ? 4 : 2} />
                                        )
                                }
                            </Grid>
                            : <PageLoader />
                    }
                </Box>
                <LayoutBuilder
                    isSublayout={false}
                    icomoon="ico-merchandising"
                    opened={openForm}
                    forClose={() => handleToggleDrawer('openForm', true)}
                    handlerSetup={() => { }}
                    dataLayout={merchandisingEdit(editType, firstCategories, currentLang)}
                    drawerWidth={props.drawerWidth}
                    allState={states}
                    stateCallback={stateCallback}
                    errorCallback={handleFormError}
                    // handleButtonGroupChange={this.handleButtonGroupChange}
                    validateButton={true}
                    handlerMutation={handlerMutation}
                    // deleteMutation={editType === "edit" ? () => { handleToggleDialog() } : null}
                    // deleteText={'Supprimer le merchandising'}
                // currentLang={currentLang}
                // handleLang={this.handleLang}
                />

                <DialogModal
                    icon={true}
                    type='delete'
                    open={openDialog}
                    onClose={handleToggleDialog}
                    title={'Êtes-vous sûr de vouloir supprimer ce groupe d\'utilisateur ?'}
                    primaryAction={deleteMutation}
                    secondaryAction={handleToggleDialog}
                    windowWidth={props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        Si vous supprimez ce groupe, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogContentText>
                </DialogModal>
            </>
        </LayoutWrapper>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsMerchandising))));