import React, {  useState } from "react";
import Div from '../shared/div';
import H2 from "../shared/h2";
import styled from 'styled-components';
import Up from "./arrow-up.svg"
import Down from "./arrow-down.svg"
import { Box } from "@material-ui/core";

const AccordionButton = styled.button`
    background-color: ${props=>props.backgroundColor ? props.backgroundColor : "rgba(240,246,234,1)"};
    color: ${props=>props.color};
    font-size:${props=>props.fontSize+ "px"};
    font-weight:bold;
    cursor: pointer;
    padding: 18px;
    box-sizing:border-box;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    &.active{
        &:after{
            content: url(${Up});     
        }
    }
    &:after{
        content: url(${Down}); 
        font-size: 13px;
        color: #777;
        float: right;
        margin-left: 5px;  
    }
`

const AccordionPanel = styled.div`
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
`


const BlocAccordion = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    const onClick = (e)=>{
        e.target.classList.toggle("active");
        const panel = e.target.nextElementSibling;
        if (panel.style.maxHeight) {
            console.log(panel.style.maxHeight)
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }


    return (
        <Div id={data.inputs.identifiant?.value} className={data.inputs.className?.value} style={{position: 'relative',padding:`40px ${data.inputs.noPaddingHorizontal?.value ? 0:"20px"}`}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            {
                data.inputs.value.value ?
                <H2
                color={data.inputs.color?.value}
                fontSize={data.inputs.fontSize?.value}                        
                style={{
                    textAlign: data.inputs.textAlign?.value,      
                    paddingTop:data.inputs.paddingTop?.value,
                    paddingBottom:data.inputs.paddingBottom?.value,  
                    fontWeight:"bold"                  
                }}
                >{data.inputs.value.value}</H2>
                :null
            }
            {
                data.blocks.accordions.config.map((accordion,index)=>{
                    return (                        
                        <Box className="dxp-accordion">
                            <AccordionButton 
                                onClick={(e) => onClick(e)} 
                                backgroundColor={accordion.blocks.title?.inputs?.backgroundColor?.value}
                                color={accordion.blocks.title?.inputs?.color.value}
                                fontSize={accordion.blocks.title?.inputs?.fontSize.value}
                                className="dxp-accordion-summary"
                            >{accordion.blocks.title?.inputs?.value.value}</AccordionButton>
                            <AccordionPanel className="dxp-accordion-detail">
                                <p dangerouslySetInnerHTML={{__html:accordion.blocks.text?.inputs?.content?.value }}/> 
                            </AccordionPanel>                            
                        </Box>
                    )
                })
            }
        </Div>
    );
};

export default BlocAccordion;
