import { TABS } from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CategoryMasterSelector from '../../../../ui/inputs/CategoryMasterSelector';
import CheckboxAttributs from '../../../../ui/inputs/CheckboxAttributs';

export default function formCategoriesEdit(currentId, categories, parentId, inputAttributes, currentLang, errors, seeErrors, handleMediaPicker, productAttributes,isCatalog = false, cms = false, assetMagentoPages = null, allState, contentCategories = null, categoryContentId) {
    let typeInput = '';

    let attributeSetup = attribute => {
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            default: typeInput = null;
        }

        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            translated: true,
            helper: {
                text: '',
                link: false,
            },
            isSystem: false,
            currentLang,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            handleMediaPicker : typeInput === 'mediaPicker' ? handleMediaPicker : null,
            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault.node.value
                });
            })
        });
    };

    let generalInputs = [
        {
            type: 'text',
            label: 'Identifiant',
            translated: false,
            helper: {
                text: 'Identifiant de la catégorie',
                link: false,
            },
            required: false,
            disabled: true,
            stateName: 'identifier',
        },
        {
            type: 'selectTree',
            label: 'Parent',
            translated: false,
            helper: {
                text: 'Indiquez le parent',
                link: false,
            },
            required: true,
            stateName: 'parent',
            data: (() => {
                let data = categories.filter(isCatalog ? e => e.parent === null : e => e.parent === null && e.libelle === 'Root');
                let populateChildren = (cats, parent) => {
                    let nameAttribute = parent.attributes?.find(e => e.identifier === 'category_name') ?? null;

                    let currentTranslation = nameAttribute?.locales.find(
                        translation => translation.code === currentLang
                    ) ?? null;

                    parent.value    = parent.id;
                    parent.label    = isCatalog && parent.parent === null ? '/' : parent.libelle === 'Root' ? '/' : currentTranslation?.value ?? nameAttribute?.locales?.[0].value ?? parent.libelle;
                    parent.disabled         = parent.id === currentId;
                    parent.children         = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
                    parent.isDefaultValue   = parent.id === parentId;
                    parent.expanded         = true;

                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(categories, parent);

                return data;
            })()
        },
    ];
    
    if (isCatalog){
        generalInputs.push({
            type: 'buttonGroup',
            label: 'Activation',
            helper: {
                label: 'État',
                text: 'Indiquez si cette catégorie est activé',
                link: false,
            },
            required: true,
            stateName: 'catActivated',
            value: [
                {
                    value: true,
                    label: 'Activé',
                },
                {
                    value: false,
                    label: 'Désactivé',
                },
            ]
        },)
    }

    let specificInputs = []; 

    if((assetMagentoPages && assetMagentoPages.length > 0) ){
        specificInputs.push({
            type: 'select',
            label: 'Type de catégorie',
            translated: false,
            helper: {
                text: 'Type de catégorie',
                link: false,
            },
            stateName: 'typeCategorie',
            value: [
                {
                    value: 'category',
                    label: 'Catégorie'
                },
                {
                    value: 'cms',
                    label: 'CMS'
                },
                {
                    value: 'content',
                    label: 'Content'
                },
            ],
            disabled: false,
        });

        if(cms === 'cms'){
            let valueCmsPages = [];
            for (let assetMagentoPage of assetMagentoPages){
                if(assetMagentoPage.node.useInCategory){
                    valueCmsPages.push({value: assetMagentoPage.node.id, label: assetMagentoPage.node.libelle});
                }
            }

            specificInputs.push({
                type: 'select',
                label: 'CMS Page',
                translated: false,
                required: true,
                helper: {
                    text: 'CMS Page',
                    link: false,
                },
                stateName: 'cmsPage',
                value: valueCmsPages
            })
        }

        if(cms === 'content'){
            specificInputs.push({
                type: 'selectTree',
                label: 'Catégorie content',
                translated: false,
                required: true,
                helper: {
                    text: 'Catégorie content',
                    link: false,
                },
                stateName: 'categoryContent',
                data: (() => {
                    let data = contentCategories.filter(e => e.parent === null && e.libelle === 'Root');

                    let populateChildren = (cats, parent) => {
                        let nameAttribute = parent.contentCategoryDatas.edges?.filter(e => e.node.attribute.identifier === 'category_name') ?? null;

                        let currentTranslation = nameAttribute?.find(
                            translation => translation.node.locale === currentLang
                        ) ?? null;

                        parent.value  = parent.id;
                        parent.label  = isCatalog && parent.parent === null ? '/' : parent.libelle === 'Root' ? '/' : currentTranslation?.value ?? nameAttribute?.[0]?.node?.value ?? parent.libelle;
                        parent.disabled = false;
                        parent.children         = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
                        parent.isDefaultValue   = parent.id === categoryContentId;
                        parent.expanded         = true;
                        for (let child of parent.children)
                            populateChildren(cats, child);
                    };

                    for (let parent of data)
                        populateChildren(contentCategories, parent);
    
                    return data;
                })()
            })
        }

        if(specificInputs.length > 0){
            generalInputs = [...specificInputs, ...generalInputs];
        }
    }

    let inputAttributesArray = [...inputAttributes?.edges];
    let productAttributesArray = null;
    if (productAttributes){
        productAttributesArray = [...productAttributes?.edges]
    }
    let attributesInputs = inputAttributesArray.map(attributeSetup);

    if(productAttributesArray){
        productAttributesArray.edges = productAttributesArray.filter(e => e.node.internalFilter);
    }

    let hasErrors = (inputs) => {
        for (let input of inputs) {
            if (errors[input.stateName])
                return true;
        }

        return false;
    };

    var obj = {
        titleForm: 'Editer une catégorie',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour modifier votre catégorie',
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: TABS,
            seeErrors,
            children: [
                {
                    labelName: 'Générale',
                    error: hasErrors(generalInputs),
                    optionsInputs: generalInputs
                },
                {
                    labelName: 'Attributs',
                    isOptionnal: false,
                    error: hasErrors(attributesInputs),
                    optionsInputs: attributesInputs
                },
            ]
        }
    };

    if (isCatalog){
        obj.formConfig.children.push(
            {
                labelName: 'Filtres',
                isOptionnal: false,
                // error: hasErrors(attributesInputs),
                optionsInputs: [
                    {
                        type: 'rows',
                        translated: false,
                        required: false,
                        stateName: 'catFilters',
                        labelsNames:{
                            first: 'Attribut',
                            second: 'Opérateur',
                            third: 'Valeurs',
                        },
                        filter: true,
                        attributes: productAttributesArray,
                        operators:[
                            {
                                id: '=',
                                label: '='
                            },
                            {
                                id: '!=',
                                label: '!='
                            },
                            {
                                id: 'IN',
                                label: 'IN'
                            },
                            {
                                id: 'NOT IN',
                                label: 'NOT IN'
                            },
                            {
                                id: 'LIKE',
                                label: 'LIKE'
                            },
                            {
                                id: 'EMPTY',
                                label: 'empty'
                            }
                        ]
                    }
                ]
            }
        )
        obj.formConfig.children.push(
            {
                labelName:  'Référence',
                isOptionnal: false,
                component: CategoryMasterSelector,
                optionsInputs:{
                    typeToLoad: 'catalog',
                    stateName: 'categoryMaster',
                    required: false,
                    value: allState
                }
            }
        )
    }

    return obj;
}