import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { SETTINGS, SETTINGS_CURRENCIES, VIEW, CREATE, IMPORT } from '../../../../js/constants/constant-rights';
import { DELETE_ASSET_FOOTER, DELETE_ASSET_FOOTER_COLUMN, DELETE_ASSET_FOOTER_COLUMN_LINK } from '../../../../queries/asset_footer';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import { eventService } from '../../../../js/services/event.service';
import * as moment from 'moment';

import colors from '../../../../config/theme/colors';

import footerEdit from './config/footer/footerEdit.config';
import { listSettings, listMappers, perPageOptions } from './config/footer/listFooter.config';
import Listing from '../../../layouts/Listing/Listing';

import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { Grid } from '@material-ui/core';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import DialogModal from '../../../ui/dialog/DialogModal';

import styled from 'styled-components';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import OurTypography from '../../../ui/typography/Typography';
import SearchBar from '../../../ui/search/SearchBar';
import OurButton from '../../../ui/button/Button';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import { withTranslation } from 'react-i18next';

const WrapperCustom = styled(Box)`
    width: 100%; 
    height: calc(100% - 64px); 
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
    display: grid; 
    /* grid-template-rows: auto auto 1fr; */
    grid-template-rows: auto 1fr;
    
    & .layout-wrapper{
        padding: 0px !important;
    }
`;
const CustomSelect = styled(Select)`
    width: 100%;
    border: 1px solid ${colors.grey.border};
    padding-left: 16px;
    padding-right: 16px;
    font-weight: bold;
    height: 40px;

    .MuiSelect-root{
        padding-top: 13px;
        padding-bottom: 13px;
    }
    fieldset{
        border: 0.5px solid ${colors.grey.lighter.hue700};
    }
`;
const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

const statusFilterOptions = {
    all: { value: 'all', label: 'Tous', status: null },
    active: { value: 'active', label: 'Actif', status: true },
    inactive: { value: 'inactive', label: 'Inactif', status: false },
}

function EngineAssetsFooterManagements(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [editType, setEditType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [reloadVersions, setReloadVersions] = React.useState(false);
    const [states, setStates] = React.useState({
        footerId: null,
        footerName: null,
        footerStartAt: null,
        footerEndAt: null,
        isDefault: false,
        isActive: true,
        footerElements: [],
        currentColumn: null,
    });

    const [isFiltered, setIsFiltered] = React.useState(false);
    const [filters, setFilters] = React.useState({
        name: '',
        status: statusFilterOptions.all.value,
    });
    let filterVariables = {
        name: null,
        status: null,
    }

    useEffect(() => {
        checkRouting(props)
        setLoading(false)
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            initNavAsset()
        }
    }, []);

    const initNavAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    const constructFooterElement = (footer) => {
        let footerElements = [];
        if (footer.assetFooterColumns.edges.length > 0) {
            let i = 0;
            for (let assetFooterColumn of footer.assetFooterColumns.edges) {
                footerElements.push({ id: assetFooterColumn.node.id, name: assetFooterColumn.node.name, position: assetFooterColumn.node.position, links: [] });
                if (assetFooterColumn.node.assetFooterColumnLinks.edges.length > 0) {
                    for (let assetFooterColumnLink of assetFooterColumn.node.assetFooterColumnLinks.edges) {
                        footerElements[i].links.push({ id: assetFooterColumnLink.node.id, name: assetFooterColumnLink.node.name, link: assetFooterColumnLink.node.link, position: assetFooterColumnLink.node.position, isExternal: assetFooterColumnLink.node.isExternal })
                    }
                    footerElements[i].links.sort((a, b) => {
                        if (a.position < b.position)
                            return -1;
                        if (a.position > b.position)
                            return 1;
                        return 0;
                    })
                }
                i++;
            }
        }
        footerElements.sort((a, b) => {
            if (a.position < b.position)
                return -1;
            if (a.position > b.position)
                return 1;
            return 0;
        });
        return footerElements;
    };

    const editHandler = (footer) => {
        setEditType('edit')
        let getState = { ...states };
        getState['footerId'] = footer.id;
        getState['footerName'] = footer.name;
        getState['footerStartAt'] = moment(footer.startedAt).utc().format('YYYY-MM-DD');
        getState['footerEndAt'] = moment(footer.endedAt).utc().format('YYYY-MM-DD');
        getState['isDefault'] = footer.isDefault;
        getState['isActive'] = footer.active;
        getState['footerElements'] = constructFooterElement(footer);

        setStates(getState);
        handleToggleDrawer('openForm');
    }

    const getFooterCallback = () => {
        setReloadVersions(false)
    }

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleButtonGroupChange = (stateName, value) => {
        let getState = { ...states };
        getState[stateName] = value;
        setStates(getState);
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const openRemove = async (footer) => {
        let getState = { ...states };
        getState['footerId'] = footer.id;
        setStates(getState);
        setOpenDialog(true);
    };

    const deleteMutation = async () => {
        props.startLoading();
        props.client.mutate({
            mutation: DELETE_ASSET_FOOTER,
            variables: { id: states.footerId }
        }).then(result => {
            props.snack(ALERT_SUCCESS, 'Footer supprimé avec succès');
        }).catch(error => {
            props.snack(ALERT_ERROR, `Impossible de supprimer le footer`);
        });
        setReloadVersions(true);
        setOpenDialog(false)
        props.stopLoading();
    };

    const deleteElement = async (element, type) => {
        let mutation;
        if (type === 'column') {
            mutation = DELETE_ASSET_FOOTER_COLUMN;
        } else if (type === 'link') {
            mutation = DELETE_ASSET_FOOTER_COLUMN_LINK;
        }

        props.client.mutate({
            mutation,
            variables: { id: element.id }
        }).then(result => {
            return true;
        }).catch(error => {
            return (error);
        });
    }

    const manageLink = async (column, item) => {
        let getState = { ...states };
        getState['currentColumn'] = column.id;
        setStates(getState);

        let j = 0;
        for (let link of item.links) {
            if (link.delete) {
                await deleteElement(link, 'link');
            }
            else if (link.new) {
                let variablesLink = {
                    assetFooterColumn: column.id,
                    name: link.name,
                    link: link.link,
                    isExternal: link.isExternal,
                    position: j,
                }
                try {
                    let createElementLink = await saveElement('assetFooterColumnLink', variablesLink, { enableLoad: false })
                }
                catch {
                    props.snack(ALERT_ERROR, 'Une erreur est survenu lors des liens du footer');
                }
                j++;
            }
            else if (link.update) {
                let variablesLink = {
                    id: link.id,
                    assetFooterColumn: column.id,
                    name: link.name,
                    link: link.link,
                    isExternal: link.isExternal,
                    position: j,
                }
                try {
                    let createElementLink = await updateElement(states, 'assetFooterColumnLink', variablesLink, null, { enableLoad: false })
                }
                catch {
                    props.snack(ALERT_ERROR, 'Une erreur est survenu lors des liens du footer');
                }
                j++;
            }
        }
    }

    const manageColumn = async (footer) => {
        let getState = { ...states };
        getState['footerId'] = footer.id;
        setStates(getState);
        let i = 0;
        for (let item of states.footerElements) {
            if (item.delete) {
                await deleteElement(item, 'column');
            }
            else if (item.new) {
                let variablesColumn = {
                    assetFooter: footer.id,
                    name: item.name,
                    position: i,
                }
                try {
                    let createElementColumn = await saveElement('assetFooterColumn', variablesColumn, { enableLoad: false })
                    if (createElementColumn) {
                        await manageLink(createElementColumn, item);
                    }
                } catch {
                    props.snack(ALERT_ERROR, 'Une erreur est survenu lors des colonnes du footer');
                }
                i++;
            }
            else if (item.update) {
                let variablesColumn = {
                    id: item.id,
                    assetFooter: footer.id,
                    name: item.name,
                    position: i,
                }
                try {
                    let createElementColumn = await updateElement(states, 'assetFooterColumn', variablesColumn, null, { enableLoad: false });
                    if (createElementColumn) {
                        await manageLink(createElementColumn, item);
                    }
                } catch {
                    props.snack(ALERT_ERROR, 'Une erreur est survenu lors des colonnes du footer');
                }
                i++;
            }
            else {
                await manageLink(item, item);
                i++;
            }
        }
    }

    const handlerMutation = async () => {
        props.startLoading();
        let variables = null;
        if (editType === "add") {
            variables = {
                name: states.footerName,
                startedAt: states.footerStartAt,
                endedAt: states.footerEndAt,
                isDefault: states.isDefault,
                active: states.isActive,
                asset: `/api/assets/${props.match.params.id}`,
            }
            try {
                let createElement = await saveElement('assetFooter', variables, { enableLoad: false })
                if (createElement) {
                    await manageColumn(createElement);
                    props.snack(ALERT_SUCCESS, 'Footer ajouté avec succès');
                    handleToggleDrawer('openForm', false);
                    setReloadVersions(true);
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du footer');
            }
        } else {
            variables = {
                id: states.footerId,
                name: states.footerName,
                startedAt: states.footerStartAt,
                endedAt: states.footerEndAt,
                isDefault: states.isDefault,
                active: states.isActive,
                asset: `/api/assets/${props.match.params.id}`,
            }
            try {
                let createElement = await updateElement(states, 'assetFooter', variables, null, { enableLoad: false });

                if (createElement) {
                    await manageColumn(createElement);
                    props.snack(ALERT_SUCCESS, 'Footer modifié avec succès');
                    handleToggleDrawer('openForm', false);
                    setReloadVersions(true);
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification du footer');
            }
        }
        props.stopLoading();
    };

    const initForm = () => {
        setEditType('add');

        let getState = { ...states };
        getState['footerId'] = null;
        getState['footerName'] = null;
        getState['footerStartAt'] = null;
        getState['footerEndAt'] = null;
        getState['isDefault'] = false;
        getState['isActive'] = true;
        getState['footerElements'] = [];

        setStates(getState);

        handleToggleDrawer('openForm');
    }

    const hasErrors = () => {
        if (errors) {
            for (let error in errors) {
                if (errors[error])
                    return true;
            }
        }
        return false;
    };

    const handleToggleDrawer = (stateDrawer, reset) => {
        setOpenForm(!openForm)
    };

    const goTo = route => {
        props.history.push(route);
    };

    const handleNameFilterChange = (event) => {
        const { value } = event.target;
        setFilters({ ...filters, name: value });
    };

    const handleStatusFilterChange = (event) => {
        const { value } = event.target;
        setFilters({ ...filters, status: value });
    };

    const handleSetRequestFilterVariables = () => {
        filterVariables = {
            name: filters.name,
            status: filters.status,
        }
    };

    /* // TODO: Filter */
    const handleFilter = (event) => {
        /* Make a request with the setted variables */

    };

    const areInputsChanged = () => filterVariables.name || filterVariables.status || filters.name !== '' || filters.status !== 'all'

    return (
        <WrapperCustom>
            <TopPanel
                icomoon="picto-analytics"
                colorIcomoon={colors.blue.darker.hue300}
                title="Gestion footer"
                subtitle="Gestion de vos footer eshop (création / modification / suppression)"
                handlerAdd={() => initForm()}
                textAdd={"+ Créer un footer"}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                openForm={openForm}
                buttonAvailable={!openForm && !loading}
                hasBorder={true}
            />

            {/* Filters */}
            {/* <Box style={{ paddingBottom: 0 }}>
                <AccordionCustom forcedExpanded={false} square={true} title={<OurTypography variant="h5" colortext={colors.black.regular} style={{ fontSize: '14px' }}>Filtres et recherche</OurTypography>}>
                    <Box style={{ display: 'flex', flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={areInputsChanged() ? 7 : 8} xs={8}>
                                <OurTypography variant="h5" colortext={colors.black.regular} style={{ fontSize: '12px', marginBottom: '10px' }}>Recherche par nom</OurTypography>
                                <SearchBar value={filters.name} noIcon style={{
                                    margin: '0 auto 8px auto',
                                    width: '100%',
                                    background: 'white'
                                }} onChange={handleNameFilterChange} />
                            </Grid>
                            <Grid item md={areInputsChanged() ? 3 : 3} xs={4}>
                                <Typography variant='body1' style={{
                                    color: colors.black.regular,
                                    fontSize: '12px',
                                    marginBottom: '6px',
                                }}>Recherche par status</Typography>
                                <CustomSelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filters.status}
                                    onChange={handleStatusFilterChange}
                                    variant="standard"
                                    disableUnderline
                                >
                                    <MenuItem value={statusFilterOptions.all.value}>{statusFilterOptions.all.label}</MenuItem>
                                    <MenuItem value={statusFilterOptions.active.value}>{statusFilterOptions.active.label}</MenuItem>
                                    <MenuItem value={statusFilterOptions.inactive.value}>{statusFilterOptions.inactive.label}</MenuItem>
                                </CustomSelect>
                            </Grid>

                            <Grid container md={areInputsChanged() ? 2 : 1} xs={12} alignItems={'center'} justifyContent={props.windowWidth >= 1250 ? 'center' : 'flex-end'} style={{
                                marginRight: props.windowWidth < 1250 ? '8px' : '0px',
                            }}>
                                {filterVariables.name || filterVariables.status || filters.name !== '' || filters.status !== 'all' ? (
                                    <OurButton onClick={(e) => {
                                        filterVariables = {
                                            name: '',
                                            status: 'all',
                                        };
                                        setFilters({
                                            name: '',
                                            status: 'all',
                                        });
                                        if (isFiltered) {
                                            handleFilter();
                                        }
                                    }} color={colors.red.darker} bgcolor={colors.white} bgcolorhover={colors.red.lighter} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, marginTop: props.windowWidth < 1250 ? '8px' : '15px' }}>Effacer</OurButton>
                                ) : null}
                                <OurButton onClick={(e) => {
                                    // Set request variables
                                    handleSetRequestFilterVariables();
                                    //Refetch filtered data Normally, just setting the request variables will get the filtered data
                                    handleFilter();
                                }} style={{ marginBottom: 0, borderRadius: 0, marginTop: props.windowWidth < 1250 ? '8px' : '15px' }}>Filtrer</OurButton>
                            </Grid>
                        </Grid>
                    </Box>
                </AccordionCustom>
            </Box> */}

            {/* Listing */}
            <Grid container style={{ height: '100%', position: 'relative' }}>
                {
                    !loading ? (
                        <Listing
                            label='footers'
                            settings={listSettings}
                            cardProps={{
                                openForm: openForm,
                                currentLang: currentLang,
                                textButton: 'Modifier',
                                handlerButton: editHandler,
                                textButtonSecondary: 'Supprimer',
                                handlerButtonSecondary: openRemove,
                                windowWidth: props.windowWidth,
                            }}
                            perPageOptions={perPageOptions}
                            mappers={listMappers}
                            currentLang={currentLang}
                            identifier='assetFooters'
                            pagination={true}
                            /* // TODO: Add filters variables when the filter button is clicked */
                            queryVariables={{
                                asset: `/api/assets/${props.match.params.id}`
                            }}
                            propsToPass={props}
                            viewsOptions={{
                                current: 'card',
                                settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                            }}
                            reload={reloadVersions}
                            listingCallback={getFooterCallback}
                            noResultComponent={() => (
                                <Box style={{
                                    height:"100%",
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center"
                                }}>
                                    <EmptyCard title={props.t("spread.active_assets.footerNotConfigured")} subtitle={props.t("spread.active_assets.clickToAdd")} textButton={props.t("spread.active_assets.addFooter")} onClick={() => initForm()} picto={EmptyAsset} openForm={openForm} xsImg={openForm ? 4 : 2} />
                                </Box>
                            )}
                        />
                    ) : <PageLoader />
                }
            </Grid>

            {/* Modify drawer layout */}
            <LayoutBuilder
                isSublayout={false}
                icomoon="ico-merchandising"
                opened={openForm}
                forClose={() => handleToggleDrawer('openForm', true)}
                handlerSetup={() => { }}
                dataLayout={editType === "add" ? footerEdit('add') : footerEdit('edit')}
                drawerWidth={props.drawerWidth}
                allState={states}
                stateCallback={stateCallback}
                errorCallback={handleFormError}
                noPadding={true}
                // // handleButtonGroupChange={this.handleButtonGroupChange}
                validateButton={true}
                handlerMutation={handlerMutation}
                handleButtonGroupChange={handleButtonGroupChange}
                backStepperButtonAction={[
                    () => { setErrors({}) },
                    () => { setErrors({}) },
                    null
                ]}
                stepperButtonAction={[
                    () => {
                        if (hasErrors()) {
                            props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                            setErrors({});
                            eventService.fire();
                            return false;
                        }
                        return true;
                    },
                    () => {
                        if (hasErrors()) {
                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                            setErrors({});
                            eventService.fire();
                            return false;
                        }
                        return true;
                    },
                    () => {
                        if (hasErrors()) {
                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                            setErrors({});
                            eventService.fire();
                            return false;
                        }
                        return true;
                    }
                ]}
            />

            {/* Delete popup */}
            <DialogModal
                icon={true}
                type='delete'
                open={openDialog}
                title={'Êtes-vous sûr de vouloir supprimer ce footer ?'}
                primaryAction={() => deleteMutation()}
                secondaryAction={handleClose}
                windowWidth={props.windowWidth}
            >
                <OurTypography text={'Si vous supprimez ce footer celui-ci ne sera plus accessible. Si vous ne l\'utilisez plus mais que vous ne souhaitez pas le supprimer, annulez la suppression et déclarer cette footer comme inactif.'} colortext={colors.grey.lighter.hue600} style={{ fontSize: '16px' }} />
            </DialogModal>
        </WrapperCustom>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(EngineAssetsFooterManagements)))));