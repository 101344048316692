import React from 'react';
import FormBuilder from '../FormBuilder';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { withTranslation } from 'react-i18next'
import { Box } from '@material-ui/core';


const AppBarCustom = styled(AppBar)`
    background: transparent;
    box-shadow: inherit;
    svg{
        fill: ${colors.black.regular};
    }
    .MuiTabs-root{
        div:first-child{
            left: 10px;
        }
        div:last-child{
            right: 10px;
        }
        .MuiTabs-scrollButtons{
            position: absolute;
            height: 48px;
        }
    }
    button{
        color: ${colors.black.regular};
        position: relative;
        z-index: 9;
        text-transform: inherit;
        font-weight: bold;
        font-size: 14px;
        opacity: 1;
    }
    .MuiTabs-scroller{
        overflow: auto !important;
        &::-webkit-scrollbar{
            width: 0px;
            height: 3px;
        }
    }
    .MuiTabs-indicator{
        top: 0;
        height: 100%;
        border-top: 2px solid ${colors.blue.darker.hue300};
        background-color: ${colors.grey.lighter.hue980}
    }
`;

class TabsFormBuilder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabState: 0,
            alreadyOpened: {}
        }
    }
    handleChange = (event, value) => {
        let alreadyOpened = this.state.alreadyOpened;

        alreadyOpened[value] = true;

        this.setState({
            tabState: value,
            alreadyOpened: { ...alreadyOpened }
        });
    };

    componentDidMount() {
        this.setState({
            tabState: this.props.allState.tabState || 0,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allState.tabState !== this.props.allState.tabState) {
            this.setState({
                tabState: this.props.allState.tabState,
            });
        }
    }

    render() {        
        return (
            <div style={{ width: '100%' }}>
                <AppBarCustom position="static">
                    <Tabs value={this.state.tabState} onChange={this.handleChange} aria-label="wrapped label tabs example" scrollButtons="on" variant="scrollable">
                        {this.props.navFormConfig.children.filter(e => e).map((child, index) => {
                            if (child !== null) {
                                return (
                                    <Tab
                                        textColor="primary"
                                        label={this.props.t(child.labelName)}
                                        wrapped
                                        key={`Tab${index}`}
                                        style={{ minWidth: 100, color: this.props.navFormConfig.seeErrors && child.error ? '#f44336' : '#2B4553' }}
                                        disabled={child.disabled}
                                    />
                                )
                            } else {
                                return null;
                            }
                        })}
                    </Tabs>
                </AppBarCustom>
                {this.props.navFormConfig.children.filter(e => e).map((child, index) =>
                (
                    child ?
                        (
                            child.component ? (
                                <Box value={this.state.tabState} hidden={this.state.tabState !== index} index={index} key={`FormBuilder${index}`} maxHeight={{ xs: '100%', md: '62vh' }} style={{ overflow: "auto" }}>
                                    {
                                        this.state.tabState !== index && !this.state.alreadyOpened[index] ? null : (
                                            <child.component
                                                stateCallback={this.props.stateCallback}
                                                errorCallback={this.props.errorCallback}
                                                allState={this.props.allState}
                                                isFormComponent={true}
                                                optionsInputs={child.optionsInputs}
                                            />
                                        )
                                    }
                                </Box>
                            ) : (
                                <Box value={this.state.tabState} hidden={this.state.tabState !== index} index={index} key={`FormBuilder${index}`} maxHeight={{ xs: '100%', md: '62vh' }} style={{ overflow: "auto", height: '100%' }}>
                                    <FormBuilder
                                        useLocking={this.props.useLocking}
                                        optionsInputs={child.optionsInputs}
                                        stateCallback={this.props.stateCallback}
                                        errorCallback={this.props.errorCallback}
                                        allState={this.props.allState}
                                        handleButtonGroupChange={this.props.handleButtonGroupChange ? this.props.handleButtonGroupChange : null}
                                    />
                                </Box>
                            )
                        )
                        : null
                )
                )}
            </div>
        )
    }
}
export default withTranslation()(TabsFormBuilder);