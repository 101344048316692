import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';

import _ from 'lodash';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import moment from 'moment';

import { ROUTE_HOME, ROUTE_SALES_SHOPS } from '../../../../js/constants/route-names';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, UPDATE, DELETE } from '../../../../js/constants/constant-rights';
import { UPDATE_PRODUCT_DATA, ADD_PRODUCT_DATA, DELETE_PRODUCT_DATA } from '../../../../queries/products';
import { GET_ATTRIBUTES_BY_TYPE, GET_ATTR_GROUPE } from '../../../../queries/attributes';
import { GET_POINTS_BY_ID, DELETE_POINTS, UPDATE_POINTS, UPDATE_POINT_DATAS, CREATE_POINT_DATAS } from '../../../../queries/locations';
import { ALLOWED } from '../../../../js/constants/medias-types';

import formStorelocatorEdit from './config/formStorelocatorEdit.config';

import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import DialogModal from '../../../ui/dialog/DialogModal';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import Button from '../../../ui/button/Button';

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const Subtitle = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
`;
const BoxCustomAttributs = styled(Box)`
    background: ${colors.grey.lighter.hue980};
    margin-top: 16px;
`;

class StorelocatorDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            idPoint: `/api/points/${props.match.params.id}`,
            categories: [],
            categoriesData: [],
            ready: false,
            description: '',
            status: '',
            openForm: false,
            openDialog: false,
            allGroups: [],
            errors: {},
            secondErrors: {},
            seeErrors: false,
            formStorelocatorEdit: formStorelocatorEdit,
        }
    }

    handleToggleDrawer = (drawer) => {
        this.setState({
            [drawer]: !this.state[drawer],
        }, () => {
            if (!this.state.drawer) {
                this.handleGetPointsById();
            }
        });
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        }
        else {
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        let value = null
        if (custom && !evt) {
            value = custom;
        }
        else {
            value = evt?.target?.value ?? evt;
        }
        this.setValue(stateName, value, translated);
    };

    deleteMutation = () => {
        let variables = { id: this.state.idPoint };

        this.props.client.mutate({
            mutation: DELETE_POINTS,
            variables,
        }).then(result => {
            this.goTo(ROUTE_SALES_SHOPS);
        })
    };

    getAttributes = () => {
        return new Promise(async (resolve, reject) => {
            const GET_ATTRIBUTES_BY_TYPE_RESULT = await this.props.client.query({
                query: GET_ATTRIBUTES_BY_TYPE,
                fetchPolicy: "no-cache",
                variables: { id: this.props.attributes.eavTypes.find(e => e.node.code === 'point').node.id }
            });
            const GET_ATTR_GROUPE_RESULT = await this.props.client.query({
                query: GET_ATTR_GROUPE,
                fetchPolicy: "no-cache",
            });
            for (let attrGroup of GET_ATTR_GROUPE_RESULT.data.attributeGroups.edges) {
                if (attrGroup.node.identifier === 'point') {
                    this.setState({ attributeGroup: attrGroup.node.id });
                }
            }
            let formStorelocatorEdit = this.state.formStorelocatorEdit;
            formStorelocatorEdit.formConfig.children[0].optionsInputs = [];
            this.setState({
                listAttributes: GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.attributes.edges,
                formStorelocatorEdit
            }, () => {
                for (let attribute of this.state.listAttributes) {
                    if (attribute.node.status) {
                        let isEmail = false;
                        const defaultLang = attribute.node.translation.translationDatas.edges[0];

                        const langSelected = attribute.node.translation.translationDatas.edges.find(
                            lang => lang.node.locale.code === this.state.currentLang
                        );
                        let typeInput = '';

                        switch (attribute.node.attributeType.input) {
                            case 'textarea':
                                typeInput = 'textarea';
                                break;
                            case 'number':
                            case 'decimal':
                                typeInput = 'decimal';
                                break;
                            case 'text':
                                typeInput = 'text';
                                break;
                            case 'mail':
                                typeInput = 'text';
                                isEmail = true;
                                break;
                            case 'link':
                                typeInput = 'text';
                                break;
                            case 'select':
                                typeInput = 'select';
                                break;
                            case 'image':
                                typeInput = 'mediaPicker';
                                break;
                            case 'file':
                                typeInput = 'file';
                                break;
                            case 'date':
                                typeInput = 'date';
                                break;
                            default: typeInput = null;
                        }
                        let input = {
                            type: typeInput,
                            label: langSelected?.node.value ?? defaultLang.node.value,
                            handleMediaPicker: typeInput === 'mediaPicker' || typeInput === 'file' ? this.handleMediaPicker : null,
                            allowedTypes: typeInput === 'file' ? ALLOWED : null,
                            translated: false,
                            helper: {
                                text: '',
                                link: false,
                            },
                            isSystem: false,
                            currentLang: this.state.currentLang,
                            required: attribute.node.isRequired,
                            stateName: attribute.node.identifier,
                            email: isEmail,
                            value: attribute.node.attributeOptions.edges.map((values) => {
                                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                                    lang => lang.node.locale.code === this.state.currentLang
                                );

                                return ({
                                    value: values.node.id,
                                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value
                                });
                            })
                        };
                        let formStorelocatorEdit = this.state.formStorelocatorEdit;
                        formStorelocatorEdit.formConfig.children[0].optionsInputs.push(input);
                        this.setState({ formStorelocatorEdit });
                    }
                }
            })
            resolve();
        });
    };

    saveAttributes = product => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes.concat(this.state.customAttributes);

            for (let attribute of attributes) {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    if (formValue && isMedia) {
                        isMedia = true;
                    }

                    if (formValue) {

                        if (currentTranslation) {
                            // UPDATE STEP
                            let variables = {
                                "id": currentTranslation.productDataId,
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "product": product,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select') {
                                    variables.value = formValue;
                                }

                            await this.props.client.mutate({
                                mutation: UPDATE_PRODUCT_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let ADD_PRODUCT_DATA_RESULT = ''
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "product": product,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select') {
                                    variables.value = formValue;
                                }
                            ADD_PRODUCT_DATA_RESULT = await this.props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP

                        await this.props.client.mutate({
                            mutation: DELETE_PRODUCT_DATA,
                            variables: { "id": currentTranslation.productDataId }
                        });
                    }
                }
            }

            resolve();
        });
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, null, selected, null);
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = (formError) => {
        if (formError === 'firstForm') {
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        } else {
            if (this.state.secondErrors) {
                for (let error in this.state.secondErrors) {
                    if (this.state.secondErrors[error])
                        return true;
                }
            }
        }


        return false;
    };

    handlerMutation = async () => {
        this.props.startLoading();
        if (this.hasErrors('firstForm')) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            this.props.stopLoading();
            return eventService.fire();
        }
        let variables = {
            id: this.state.point.id,
            updatedAt: moment().format('YYYY-MM-DD'),
            status: this.state.point.status,
        };
        this.props.client.mutate({
            mutation: UPDATE_POINTS,
            variables,
        }).then(result => {
            this.setState({
                point: result.data.updatePoint.point
            }, async () => {
                let i = 0;
                for (let attribute of this.state.listAttributes) {
                    i++;
                    if (attribute.node.status) {
                        let typeInput = '';
                        let isEmail = false;
                        switch (attribute.node.attributeType.input) {
                            case 'textarea':
                                typeInput = 'textarea';
                                break;
                            case 'number':
                            case 'decimal':
                                typeInput = 'decimal';
                                break;
                            case 'text':
                                typeInput = 'text';
                                break;
                            case 'mail':
                                typeInput = 'text';
                                isEmail = true;
                                break;
                            case 'link':
                                typeInput = 'text';
                                break;
                            case 'select':
                                typeInput = 'select';
                                break;
                            case 'image':
                                typeInput = 'mediaPicker';
                                break;
                            case 'file':
                                typeInput = 'file';
                                break;
                            case 'date':
                                typeInput = 'date';
                                break;
                            default: typeInput = null;
                        }
                        let check = false;
                        let idPointData = null;
                        for (let data of this.state.point.pointDatas.edges) {
                            if (data.node.attribute.id === attribute.node.id) {
                                check = true;
                                idPointData = data.node.id;
                            }
                        }
                        if (check) {
                            let variables = {
                                id: idPointData,
                                point: this.state.point.id,
                                attribute: attribute.node.id,
                                locale: this.props.locales[0].node.id
                            }
                            if (typeInput === 'mediaPicker') {
                                variables.media = this.state[attribute.node.identifier]?.id || null;
                            }
                            else if (typeInput === 'select') {
                                variables.attributeOption = this.state[attribute.node.identifier];
                            }
                            else {
                                variables.value = this.state[attribute.node.identifier];
                            }

                            let result = await this.props.client.mutate({
                                mutation: UPDATE_POINT_DATAS,
                                variables
                            });

                            if (i === this.state.listAttributes.length) {
                                this.setState({ openForm: false });
                                this.handleGetPointsById();
                            }
                        } else {
                            let variables = {
                                "point": this.state.point.id,
                                "attribute": attribute.node.id,
                                "locale": this.props.locales[0].node.id
                            }
                            if (typeInput === 'mediaPicker') {
                                variables.media = this.state[attribute.node.identifier]?.id || null;
                            }
                            else if (typeInput === 'select') {
                                variables.attributeOption = this.state[attribute.node.identifier];
                            }
                            else {
                                variables.value = this.state[attribute.node.identifier];
                            }

                            let result = await this.props.client.mutate({
                                mutation: CREATE_POINT_DATAS,
                                variables
                            });

                            if (i === this.state.listAttributes.length) {
                                this.setState({ openForm: false });
                                this.handleGetPointsById();
                            }
                        }
                    }
                }

            });
        });
    };

    handleGetPointsById = () => {
        return new Promise((resolve, reject) => {
            let variables = { id: this.state.idPoint }
            this.props.client.query({
                query: GET_POINTS_BY_ID,
                fetchPolicy: 'no-cache',
                variables,
            }).then(result => {
                this.setState({
                    point: result.data.point
                }, () => {
                    for (let data of this.state.point.pointDatas.edges) {
                        let typeInput = null;
                        let isEmail = false;
                        switch (data.node.attribute.attributeType.input) {
                            case 'textarea':
                                typeInput = 'textarea';
                                break;
                            case 'number':
                            case 'decimal':
                                typeInput = 'decimal';
                                break;
                            case 'text':
                                typeInput = 'text';
                                break;
                            case 'mail':
                                typeInput = 'text';
                                isEmail = true;
                                break;
                            case 'link':
                                typeInput = 'text';
                                break;
                            case 'select':
                                typeInput = 'select';
                                break;
                            case 'image':
                                typeInput = 'mediaPicker';
                                break;
                            case 'file':
                                typeInput = 'file';
                                break;
                            case 'date':
                                typeInput = 'date';
                                break;
                            default: typeInput = null;
                        }
                        let value = null;
                        if (typeInput === 'select') {
                            value = data.node.attributeOption?.id;
                        }
                        else if (typeInput === 'mediaPicker') {
                            value = data.node.media;
                        }
                        else {
                            value = data.node.value;
                        }
                        this.setState({
                            [data.node.attribute.identifier]: value
                        });
                    }
                    this.props.stopLoading();
                    resolve();
                });
            });
        });
    }

    async getAll() {
        await this.handleGetPointsById();
        this.setState({ ready: true });
    }

    componentDidMount() {
        this.getAll();
        this.getAttributes();
    }

    render() {

        return (
            <>
                <CardCustom style={{ width: this.state.openForm && this.props.windowWidth > 1500 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "50%", marginLeft: 16, marginTop: 24, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                    {
                        this.state.ready ? (
                            <>
                                <BoxCustom onClick={this.props.history.goBack} style={{ marginBottom: 16 }}>
                                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                                </BoxCustom>
                                <Grid item xs={12}>
                                    <Typography variant="h2">{this.state.point.pointDatas.edges.find(item => item.node.attribute?.identifier === 'point_name')?.node.value}</Typography>
                                    <Typography variant="h3">{this.state.point.pointDatas.edges.find(item => /ad*res*e?/gm.test(item.node.attribute?.identifier) === true)?.node.value}</Typography>
                                    <BoxCustomAttributs container direction="row">
                                        {
                                            this.state.listAttributes ? (
                                                this.state.listAttributes.map((attribute) => (
                                                    attribute.node.status ? (
                                                        <div style={{ display: 'flex', flexDirection: 'row', padding: '4px 16px', borderBottom: '1px solid white' }}>
                                                            {
                                                                attribute.node.attributeType.input === 'image' ? (
                                                                    <>
                                                                        <p>{attribute.node.translation.translationDatas.edges[0].node.value} - {!this.state[attribute.node.identifier]?.filePath ? (<strong style={{ maxWidth: '50%', paddingLeft: 8 }}>Aucune image</strong>) : null}</p>
                                                                        {this.state[attribute.node.identifier]?.filePath ? (<img style={{ maxWidth: '50%', paddingLeft: 8 }} src={`${process.env.REACT_APP_MEDIAS}/${this.state[attribute.node.identifier]?.filePath}`} />) : null}
                                                                    </>
                                                                ) : attribute.node.attributeType.input === 'select' ? (
                                                                    <p>{attribute.node.translation.translationDatas.edges[0].node.value} - <strong>{attribute.node.attributeOptions.edges.find(item => item.node.id === this.state[attribute.node.identifier])?.node.translation.translationDatas.edges[0].node.value ? attribute.node.attributeOptions.edges.find(item => item.node.id === this.state[attribute.node.identifier])?.node.translation.translationDatas.edges[0].node.value : 'Aucune valeur'}</strong></p>
                                                                ) : (<p>{attribute.node.translation.translationDatas.edges[0].node.value} - <strong>{this.state[attribute.node.identifier] ? this.state[attribute.node.identifier] : 'Aucune valeur'}</strong></p>)
                                                            }
                                                        </div>
                                                    ) : null
                                                ))
                                            ) : null
                                        }
                                    </BoxCustomAttributs>
                                </Grid>
                                <Grid container direction="row" justifyContent="flex-end">
                                    <Box>
                                        <Button text="Modifier" disabled={this.state.ready ? (this.state.openForm ? this.state.openForm : false) : true} bgColor={colors.green.regular} onClick={() => this.handleToggleDrawer('openForm')} arrow={'right'} shadowcolor={colors.green.darker} />
                                    </Box>
                                </Grid>
                            </>
                        ) : <PageLoader />
                    }
                </CardCustom>
                <LayoutBuilder
                    isSublayout={false}
                    validateButton={true}
                    opened={this.state.openForm}
                    forClose={() => this.handleToggleDrawer('openForm')}
                    handlerMutation={this.handlerMutation}
                    icomoon={'picto-retailer'}
                    dataLayout={this.state.formStorelocatorEdit}
                    allState={this.state}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    drawerWidth={this.props.drawerWidth}
                    deleteMutation={this.handleToggleDialog}
                    deleteText={'Supprimer le magasin'}
                    deleteButton={this.state.editForm}
                />
                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    title={`Êtes-vous sûr de vouloir supprimer ce magasin ?`}
                    secondaryAction={this.handleToggleDialog}
                    primaryAction={this.deleteMutation}
                    windowWidth={this.props.windowWidth}
                >
                    Si vous supprimez ce magasin celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                </DialogModal>
            </>
        );
    }

    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(StorelocatorDetail)));