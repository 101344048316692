import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CardCustom from '../../../layouts/Card/CardCustom';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_HOME, ROUTE_SALES_SHOPS_DETAILS, ROUTE_ATTRIBUTS_POINT } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_POINTS, CREATE_POINTS, CREATE_POINT_DATAS } from '../../../../queries/locations';
import { GET_ATTRIBUTES_BY_TYPE, GET_ATTR_GROUPE } from '../../../../queries/attributes';
import { ALLOWED } from '../../../../js/constants/medias-types';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formStorelocatorAdd from './config/formStorelocatorAdd.config';
import EmptyRetailers from '../../../../assets/pictos/empty-picto/empty_retailers.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';

import Button from '../../../ui/button/Button';

import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, CREATE } from '../../../../js/constants/constant-rights';
import '../../../navigation/DrawerLeft.scss';
import moment from 'moment';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;

`

const styles = theme => ({
});

class ListStorelocator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            ready: false,
            errors: {},
            listPoints: [],
            formStorelocatorAdd: formStorelocatorAdd,
        }
        this.errors = {}
    }

    handlerMutation = async () => {
        this.props.startLoading();
        if (this.hasErrors('firstForm')) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            this.props.stopLoading();
            return eventService.fire();
        }
        let variables = {
            attributeGroup: this.state.attributeGroup,
            created: moment().format('YYYY-MM-DD'),
            updatedAt: moment().format('YYYY-MM-DD'),
            status: true,
        };
        this.props.client.mutate({
            mutation: CREATE_POINTS,
            variables
        }).then(result => {
            this.setState({
                newPoint: result.data.createPoint.point
            }, async () => {
                let i = 0;
                for (let attribute of this.state.listAttributes) {
                    i++;
                    if (attribute.node.status) {
                        let typeInput = '';
                        let isEmail = false;
                        switch (attribute.node.attributeType.input) {
                            case 'textarea':
                                typeInput = 'textarea';
                                break;
                            case 'number':
                            case 'decimal':
                                typeInput = 'decimal';
                                break;
                            case 'text':
                                typeInput = 'text';
                                break;
                            case 'mail':
                                typeInput = 'text';
                                isEmail = true;
                                break;
                            case 'link':
                                typeInput = 'text';
                                break;
                            case 'select':
                                typeInput = 'select';
                                break;
                            case 'image':
                                typeInput = 'mediaPicker';
                                break;
                            case 'file':
                                typeInput = 'file';
                                break;
                            case 'date':
                                typeInput = 'date';
                                break;
                            default: typeInput = null;
                        }

                        let variables = {
                            "point": this.state.newPoint.id,
                            "attribute": attribute.node.id,
                            "locale": this.props.locales[0].node.id
                        }
                        if (typeInput === 'mediaPicker' && this.state[attribute.node.identifier]?.id) {
                            variables.media = this.state[attribute.node.identifier].id;
                        }
                        else if (typeInput === 'select') {
                            variables.attributeOption = this.state[attribute.node.identifier];
                        }
                        else if (this.state[attribute.node.identifier]) {
                            variables.value = this.state[attribute.node.identifier];
                        }

                        let result = await this.props.client.mutate({
                            mutation: CREATE_POINT_DATAS,
                            variables
                        });

                        if (i === this.state.listAttributes.length) {
                            this.setState({ openForm: false });
                            this.handleGetPoints();
                            this.props.stopLoading();
                        }
                    }
                }
            });
        });
    };

    handleGetPoints = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_POINTS,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    listPoints: result.data.points.edges
                }, () => {
                    this.props.stopLoading();
                    resolve();
                });
            });
        });
    };

    handleToggleDrawer = (stateDrawer) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
            this.forceUpdate();
        });
    };

    async getAll() {
        await this.handleGetPoints();
        this.setState({ ready: true });
    }

    componentDidMount() {
        this.getAll();
        this.getAttributes();
    }

    render() {
        return (
            <div>
                <TopPanel
                    icomoon="picto-retailer"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={"Gérer les magasins"}
                    subtitle={"Liste de vos magasins (création / modification / suppression)"}
                    handlerAdd={() => this.handleToggleDrawer('openForm')}
                    textAdd={"Ajouter un magasin"}
                    textImport={"Accéder aux attributs magasins"}
                    handlerImport={() => this.goTo(ROUTE_ATTRIBUTS_POINT)}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    locales={this.props.locales}
                    hasBorder={true}
                />
                <Grid container direction="row" style={{ paddingTop: 16 }} spacing={2}>
                    <Grid item md={this.state.openForm && this.state.noResult ? 6 : 12} sm={9} xs={9}>
                        <Grid container direction="row" spacing={2}>
                            {
                                !this.state.ready ?
                                    <PageLoader />
                                    : this.state.listPoints && this.state.listPoints.length > 0 && !this.state.noResult ?
                                        (
                                            this.state.listPoints.map((point, index) => {
                                                let namePoint = point.node.pointDatas.edges.find(item => item.node.attribute.identifier === 'point_name')?.node.value;
                                                let address = point.node.pointDatas.edges.find(item => /ad*res*e?/gm.test(item.node.attribute.identifier) === true)?.node.value;
                                                return (
                                                    <GridCustom item lg={3} md={4} sm={6} xs={12} key={`ListProduct${index}`}>
                                                        <CardCustom style={{ width: "100%", height: "100%" }} cardContentStyle={{ height: "100%", paddingBottom: 80, position: 'relative' }} hovercard={true}>
                                                            <Typography variant="h2">{namePoint}</Typography>
                                                            {address ?
                                                                (
                                                                    <Typography variant="body1">{address}</Typography>
                                                                ) : null
                                                            }
                                                            <ContainerButton pt={1} style={{ position: 'absolute', bottom: 10, right: 10 }}>
                                                                <Button disableElevation={true} text="Voir le magasin" arrow="right" bgColor={colors.green.regular} onClick={() => this.goTo(ROUTE_SALES_SHOPS_DETAILS.replace(':id', point.node.id.replace('/api/points/', '')))} style={{ margin: 0 }} />
                                                            </ContainerButton>
                                                        </CardCustom>
                                                    </GridCustom>
                                                )
                                            })
                                        )
                                        : this.state.noResult ?
                                            (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={"Ajouter un magasin"} onClick={() => this.handleToggleDrawer('openForm')} picto={EmptyRetailers} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} mtImg={2} />)
                                            : (<EmptyCard title={"Vous n'avez pas encore configuré de magasin"} subtitle={"Ajouter un magasin en cliquant ci-dessous"} textButton={"Ajouter un magasin"} onClick={() => this.handleToggleDrawer('openForm')} picto={EmptyRetailers} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <LayoutBuilder
                    isSublayout={false}
                    validateButton={true}
                    opened={this.state.openForm}
                    forClose={() => this.handleToggleDrawer('openForm')}
                    handlerMutation={this.handlerMutation}
                    icomoon={'picto-retailer'}
                    dataLayout={this.state.formStorelocatorAdd}
                    allState={this.state}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    drawerWidth={this.props.drawerWidth}
                    deleteButton={false}
                />
            </div>
        );
    }

    getAttributes = () => {
        return new Promise(async (resolve, reject) => {
            const GET_ATTRIBUTES_BY_TYPE_RESULT = await this.props.client.query({
                query: GET_ATTRIBUTES_BY_TYPE,
                fetchPolicy: "no-cache",
                variables: { id: this.props.attributes.eavTypes.find(e => e.node.code === 'point')?.node.id }
            });
            const GET_ATTR_GROUPE_RESULT = await this.props.client.query({
                query: GET_ATTR_GROUPE,
                fetchPolicy: "no-cache",
            });
            for (let attrGroup of GET_ATTR_GROUPE_RESULT.data.attributeGroups.edges) {
                if (attrGroup.node.identifier === 'point') {
                    this.setState({ attributeGroup: attrGroup.node.id });
                }
            }
            let formStorelocatorAdd = this.state.formStorelocatorAdd;
            formStorelocatorAdd.formConfig.children[0].optionsInputs = [];
            this.setState({
                listAttributes: GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.attributes.edges,
                formStorelocatorAdd
            }, () => {
                for (let attribute of this.state.listAttributes) {
                    if (attribute.node.status) {
                        let isEmail = false;
                        const defaultLang = attribute.node.translation.translationDatas.edges[0];

                        const langSelected = attribute.node.translation.translationDatas.edges.find(
                            lang => lang.node.locale.code === this.state.currentLang
                        );
                        let typeInput = '';

                        switch (attribute.node.attributeType.input) {
                            case 'textarea':
                                typeInput = 'textarea';
                                break;
                            case 'number':
                            case 'decimal':
                                typeInput = 'decimal';
                                break;
                            case 'text':
                                typeInput = 'text';
                                break;
                            case 'mail':
                                typeInput = 'text';
                                isEmail = true;
                                break;
                            case 'link':
                                typeInput = 'text';
                                break;
                            case 'select':
                                typeInput = 'select';
                                break;
                            case 'image':
                                typeInput = 'mediaPicker';
                                break;
                            case 'file':
                                typeInput = 'file';
                                break;
                            case 'date':
                                typeInput = 'date';
                                break;
                            default: typeInput = null;
                        }
                        let input = {
                            type: typeInput,
                            label: langSelected?.node.value ?? defaultLang.node.value,
                            handleMediaPicker: typeInput === 'mediaPicker' || typeInput === 'file' ? this.handleMediaPicker : null,
                            allowedTypes: typeInput === 'file' ? ALLOWED : null,
                            translated: false,
                            helper: {
                                text: '',
                                link: false,
                            },
                            isSystem: false,
                            currentLang: this.state.currentLang,
                            required: attribute.node.isRequired,
                            stateName: attribute.node.identifier,
                            email: isEmail,
                            value: attribute.node.attributeOptions.edges.map((values) => {
                                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                                    lang => lang.node.locale.code === this.state.currentLang
                                );

                                return ({
                                    value: values.node.id,
                                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value
                                });
                            })
                        };
                        let formStorelocatorAdd = this.state.formStorelocatorAdd;
                        formStorelocatorAdd.formConfig.children[0].optionsInputs.push(input);
                        this.setState({ formStorelocatorAdd, [attribute.node.identifier]: null });
                    }
                }
            })
            resolve();
        });
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        }
        else {
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        let value = null
        if (custom && !evt) {
            value = custom;
        }
        else {
            value = evt?.target?.value ?? evt;
        }
        this.setValue(stateName, value, translated);
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, null, selected, null);
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    goTo = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { pointId: id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListStorelocator))));