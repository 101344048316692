import React, { useEffect }  from 'react';

import { Box, Fade, Grid, makeStyles, Menu, MenuItem } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import { ROUTE_CATALOGS_DETAIL } from '../../../../js/constants/route-names';
import styled from 'styled-components';
import * as moment from 'moment';
import 'moment-timezone';

import SyncIcon from '@material-ui/icons/Sync';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';

import StatusInfo from '../../../ui/status-info/StatusInfo';
import { withTranslation } from 'react-i18next';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';
import TextEllipsis from './TextEllipsis';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import OurMenu from '../../../ui/menu/Menu';
import DialogModal from '../../../ui/dialog/DialogModal';


const ContainerImage = styled(Box)`
    border: 1px solid ${colors.grey.lighter.hue700};
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    
`
const ContainerStyled = styled(Grid)`
`
const CatalogDescription = styled(Typography)`
    display: flex;
    flex-direction: row;
    min-height: 34px;
    overflow: hidden;
    font-size: 13px;
    line-height: 17px;
    margin-top: 4px;
    span{
        white-space: nowrap;
    }
`
const Libelle = styled(Typography)`
    font-size: 25px;
    line-height: 1.5;
    font-weight: bold;
    max-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
`

const WrapperAttr = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 8px;
    }
    > p:first-child{
        min-width: 200px;
    }
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`
const MenuItemCustom = styled(MenuItem)`
    &:hover, &:focus{
        background: ${props => props.colorhover} !important;
        span{
            color: white !important;
        }
        svg{
            fill:white !important;
        }
    }
`
const useStyles = makeStyles((theme) => ({
    editIcon: {
        position: 'absolute',
        color: colors.black.regular,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        top: -10,
        right: 10,
        transition: 'all .5s',
        display: 'none',
    },
    menu: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    paper: {
        border: '1px solid #d3d4d5',
    }
}));

function CardCatalog(props){
    const [nbProducts, setNbProducts] = React.useState(0);
    const [catalogData, setCatalogData] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const open = Boolean(anchorEl);

    const classes = useStyles();

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {catalogId : id}
        });
    };

    let calcProduct = 0;

    useEffect(() => {
        if (catalogData !== props.data.node){
            let toDateStart = moment(props.data.node.startAt).format('L');
            let toDateEnd = moment(props.data.node.endAt).format('L');

            setCatalogData(props.data.node);
            setStartDate(toDateStart);
            setEndDate(toDateEnd);

            if (props.nbProducts){
                setNbProducts(props.nbProducts);
            }else{
                // TODO OPTIMISATION CATEGORIES
                // if(props.data.node.categories.length === 0){
                //     setNbProducts(0);
                // }
                // else{
                //     for (let item of props.data.node.categories){
                //         if(item.productCategories?.totalCount){
                //             calcProduct = calcProduct + item.productCategories.totalCount;
                //             setNbProducts(calcProduct);
                //         }
                //     }
                // }
            }
        }
    });
    

    const editMenuOptions = [
        {
            label: 'Dupliquer',
            color: colors.blue.darker.hue300,
            isDisabled: false,
            icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
                props.actionButtonSecond();
                handleClose(e);
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                setOpenDeleteModal(!openDeleteModal);
                handleClose(e);
            }
        }
    ]
    return(
        catalogData ?
        (

            <Box width={"100%"} style={{cursor: props.inputCard ? 'inherit' : 'pointer'}} onClick={props.inputCard ? null : () => goTo(ROUTE_CATALOGS_DETAIL.replace(':id', catalogData.id.replace('/api/catalogs/', '')))}>

                <Box padding={'16px 16px 22px 16px'}>
                    <ContainerImage height={210} backgroundimage={catalogData.media ? `${process.env.REACT_APP_MEDIAS}/${catalogData.media.filePath}` : ImgNotFound} />
                    <Box pb={2} mt={2} style={{borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`, width: "100%"}}>
                        {/* <ContainerStyled 
                            container 
                            direction="row"
                            justifyContent="space-between"
                            alignItems={props.openForm ? "flex-start" : "center"}
                            style={{marginTop: 19}}
                        >
                            {
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    <Grid item xs={props.openForm ? 8 : 10}> 
                                        <Grid container alignItems="center">
                                            <div style={{minHeight: 15, width: '44%'}} />
                                        </Grid>
                                    </Grid>
                                : null
                            }
                        </ContainerStyled> */}
                        <Grid container justifyContent='space-between' alignItems='flex-start'>
                            <Libelle variant="h4" colortext={colors.black.regular}>
                                {catalogData.libelle}
                            </Libelle>
                            <Box mt={'10px'}>
                                <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />  
                            </Box>          
                        </Grid>
                        <CatalogDescription variant="body1" component="div">                            
                            <TextEllipsis title={catalogData.libelle} text={catalogData.description} maxLine={2} ellipsis={'...'} hasModal={false}/>
                        </CatalogDescription>
                    </Box>
                    <Box pb={2} mt={2} style={{width: "100%"}}>
                        <WrapperAttr container>
                            <Typography>{props.t("products.list.cardproduct.status")} :</Typography>
                            <Box style={{marginLeft:'3px'}}>
                                <StatusInfo status={catalogData.status} width={'auto'} fontSize={14}/>
                            </Box>
                        </WrapperAttr>
                        <WrapperAttr container>
                            <Typography>{props.t("catalogs.catalogs.synchro")} :</Typography>
                            {
                                catalogData.autosync ? (
                                    <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <SyncIcon style={{fill: colors.green.regular, fontSize: '1.2rem'}} />
                                        <Typography variant="body1" component="div" style={{color: colors.green.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                            {props.t("catalogs.catalogs.synchroProduct")}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <SyncProblemIcon style={{fill: colors.orange.regular, fontSize: '1.2rem'}} />
                                        <Typography variant="body1" component="div" style={{color: colors.orange.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                            {props.t("catalogs.catalogs.noSynchroProduct")}
                                        </Typography>
                                    </div>
                                )
                            }
                        </WrapperAttr>
                    </Box>
                    
                    <Grid
                        container 
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {
                            !props.configCard ? 
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Box width={"100%"}>
                                            <Button 
                                          text={props.textButton} 
                                            style={{padding: "14px 32px", margin: 0, width: "100%"}}
                                            disableElevation={true}  
                                            onClick={() => goTo(ROUTE_CATALOGS_DETAIL.replace(':id', catalogData.id.replace('/api/catalogs/', '')))}/>
                                        </Box>
                                    </Grid>
                                </Grid> 
                            : null
                        }
                    </Grid>
                </Box>
                {/* Delete modal */}
                <DialogModal
                    open={openDeleteModal}
                    icon={true}
                    type='delete'
                    title={'Êtes-vous sûr de vouloir supprimer ce catalogue ?'}
                    primaryAction={(event) => {
                        event.stopPropagation();
                        props.actionButtonDelete();
                        setOpenDeleteModal(false);
                        event.stopPropagation();
                    }}
                    secondaryAction={(event) => {
                        event.stopPropagation();
                        setOpenDeleteModal(false);
                    }}
                    windowWidth={props.windowWidth}
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce catalogue ? <strong>Cette action est irréversible</strong></Typography>
                </DialogModal>

                </Box>
        )
        : null
    )
}

export default withTranslation()(withRouter(CardCatalog));
