import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import { toggleExpandedForAll } from 'react-sortable-tree';

import { withApollo } from 'react-apollo';
import { GET_CONTENT_CATEGORIES_ONLY, UPDATE_CONTENT_CATEGORY, ADD_CONTENT_CATEGORY, DELETE_CONTENT_CATEGORY, UPDATE_CONTENT_CATEGORY_DATA, ADD_CONTENT_CATEGORY_DATA, DELETE_CONTENT_CATEGORY_DATA } from '../../../../queries/content_categories';
import formContentCategoriesAdd from './config/formContentCategoriesAdd.config.js';
import formContentCategoriesEdit from './config/formContentCategoriesEdit.config.js';
import fetch from '../../../../js/utils/fetch';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import Button from '../../../ui/button/Button';

import importCategoriesTypesConfig from './config/importCategoriesTypes.config';
import importCategoriesConfig from './config/importCategories.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';

import colors from '../../../../config/theme/colors';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import slugify from 'slugify';
import request from '../../../../js/utils/fetch';

import { CONTENTS, CONTENTS_CATEGORIES, VIEW, CREATE, IMPORT, UPDATE, DELETE } from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { GET_MEDIA_CATEGORIES } from '../../../../queries/mediaCategories';




class ContentsCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false,
            openDialog: false,
            editForm: false,
            openFormImports: false,
            openFormImport: false,
            cat_id: '',
            parent: '',
            libelle: '',
            categoriesData: [],
            mediaCategories: [],
            treeData: null,
            inputAttributes: [],
            currentLang: props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            selectedMediaCategories: []
        };

        this.stats =
            [
                {
                    'icon': 'picto-stock',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.pink.regular,
                    'subtitle': 'Mise à jour des Stocks',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
                {
                    'icon': 'picto-produit',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.pink.regular,
                    'subtitle': 'Mise à jour des Produits',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
                {
                    'icon': 'picto-prix',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.pink.regular,
                    'subtitle': 'Mise à jour des Prix',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
            ];
        this.typingTimer = null;
        this.typeTesting = "category";
    }

    componentDidMount() {
        checkRouting(this.props);
        this.prepareTree();
        this.getMediasCategories();
    }

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.currentNode)
            return null;

        let attribute = this.state.currentNode.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    saveAttributes = (cat) => {
        return new Promise(async (resolve, reject) => {
            let category = cat || this.state.currentNode;

            for (let attribute of this.props.attributes.category.attributes.edges) {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia = false;

                    if (formValue && attribute.node.attributeType.input === 'image') {
                        /*if (!formValue.changed)
                            continue;*/

                        isMedia = true;
                        /*formValue   = formValue.file;*/
                    }

                    if (formValue) {
                        /*let resultMedia = null;
    
                        if (attribute.node.attributeType.input === 'image') { 
                            let formData = new FormData();
    
                            formData.append('file', formValue);
                            formData.append('type', formValue.type);
        
                            resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                        }*/

                        if (currentTranslation) {
                            // UPDATE STEP
                            let variables = {
                                "id": currentTranslation.categoryDataId,
                                "contentCategory": category.id,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia)
                                variables.media = formValue.id;

                            if (!isMedia)
                                variables.value = formValue;

                            await this.props.client.mutate({
                                mutation: UPDATE_CONTENT_CATEGORY_DATA,
                                variables
                            });

                            /* try {
                                if (isMedia)
                                    await axios(`${process.env.REACT_APP_API}/media-objects/${currentTranslation.media.id.replace('/api/media-objects/', '')}`, 'delete');
                            } catch(e) {
                            }*/
                        } else {
                            // CREATE STEP
                            let variables = {
                                "contentCategory": category.id,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia)
                                variables.media = formValue.id;

                            if (!isMedia)
                                variables.value = formValue;

                            await this.props.client.mutate({
                                mutation: ADD_CONTENT_CATEGORY_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP

                        await this.props.client.mutate({
                            mutation: DELETE_CONTENT_CATEGORY_DATA,
                            variables: { "id": currentTranslation.categoryDataId },
                            refetchQueries: [{ query: GET_CONTENT_CATEGORIES_ONLY }]
                        });
                    }
                }
            }

            resolve();
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.prepareTree();

        this.props.snack(ALERT_SUCCESS, this.state.editForm === 'edit'
            ? 'Catégorie modifiée !'
            : 'Catégorie ajoutée !');

        this.handleToggleDrawer();
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            let query = null;
            let variables = null;

            this.props.startLoading();

            switch (this.state.editForm) {
                case 'edit':
                    query = UPDATE_CONTENT_CATEGORY;
                    variables = {
                        id: this.state.cat_id,
                        libelle: this.state.identifier,
                        parent: this.state.parent,
                        mediaObjectCategories: this.state.selectedMediaCategories.map(e => e.id)
                    };
                    break;
                case 'add':
                    query = ADD_CONTENT_CATEGORY;
                    variables = {
                        libelle: this.state.identifier,
                        parent: this.state.parent,
                        master: null,
                        mediaObjectCategories: this.state.selectedMediaCategories.map(e => e.id)
                    };
                    break;
                default: return this.props.stopLoading();
            }

            const GET_CATEGORIES_RESULT = await this.props.client.mutate({
                mutation: query,
                variables,
                refetchQueries: [{
                    query: GET_CONTENT_CATEGORIES_ONLY,
                }]
            });

            await this.saveAttributes(this.state.editForm === 'edit'
                ? GET_CATEGORIES_RESULT.data.updateContentCategory.contentCategory
                : GET_CATEGORIES_RESULT.data.createContentCategory.contentCategory);

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;

        this.props.startLoading();

        query = DELETE_CONTENT_CATEGORY;
        variables = { id: this.state.cat_id };

        this.props.client.mutate({
            mutation: query,
            variables
        }).then(() => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Catégorie supprimée');

            this.removeNode(this.state.cat_id);

            // this.handleToggleDrawer();
            this.handleToggleDialog();
            this.resetState();
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer la catégorie, veuillez vérifier qu'elle n'est pas utilisée`);
            this.handleToggleDialog();
        });
    };

    removeNode = (id) => {
        let removeRecursive = (data) => {
            if (data.children) {
                data.children = this.copyArrayOfObjects(data.children.filter(e => e.id !== id));

                for (let child of data.children)
                    removeRecursive(child);
            }
        };

        let tree = this.state.treeData;

        for (let data of tree)
            removeRecursive(data, id);

        this.setState({ treeData: this.copyArrayOfObjects(tree) });
    };

    recursiveTraductionCheck = (cat, isRoot) => {
        this.convertToNode(cat, isRoot);

        for (let child of cat.children)
            this.recursiveTraductionCheck(child, false);
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            for (let cat of this.state.treeData) {
                this.recursiveTraductionCheck(cat, true);
            }

            this.forceUpdate();
        });
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm,
            seeErrors: false,
            errors: {}
        });
    };

    handleToggleDrawerImport = (stateDrawer) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
    };

    handleToggleDialog = (node = null) => {
        if (node) {
            this.setState({
                cat_id: node,
            })
        }

        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    resetState() {
        this.setState({
            cat_id: '',
            identifier: '',
            parent: '',
            errors: {},
            selectedMediaCategories: []
        });
    }

    editCat = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of nodeInfo.attributes) {
                for (let attributeLocale of attribute.locales) {
                    if (attributeLocale.code === locale.node.code) {
                        switch (attribute.attributeType.input) {
                            case 'image':
                                values[attribute.identifier] = {
                                    data: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    file: null,
                                    changed: false
                                };
                                break;
                            default:
                                values[attribute.identifier] = attributeLocale.value;
                        }

                    }
                }
            }

            this.setState({
                [locale.node.code]: values,
            });
        }

        this.setState({
            currentNode: nodeInfo,
            openForm: true,
            editForm: 'edit',
            cat_id: nodeInfo.id,
            identifier: nodeInfo.libelle,
            parent: nodeInfo.parent === null ? nodeInfo.id : nodeInfo.parent.id,
            selectedMediaCategories: nodeInfo.mediaObjectCategories.edges.map(e => e.node)
        });
    };
    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    }
    addCategory = () => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {},
            });
        }

        let defaultCat = this.state.categoriesData.find(e => e.parent === null);

        this.setState({
            openForm: true,
            editForm: 'add',
            parent: defaultCat.id,
        });
    };

    addSubcategory = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {},
            });
        }

        this.setState({
            openForm: true,
            editForm: 'add',
            parent: nodeInfo.id,
        });
    };

    doneTyping = (stateName) => {
        if (stateName === 'identifier') {
            this.setState({
                catalogIdentifier: slugify(this.state.identifier, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g })
            })
        }
        if (this.state.identifier) {
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.identifier}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'identifier', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.' });
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if (stateName === 'identifier') {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];
            if (!values) {
                values = {};
            }
            values[stateName] = value;
            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
        if (stateName === 'identifier')
            this.checkIdentifier(stateName);
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    expand = (expanded) => {
        this.setState({
            treeData: toggleExpandedForAll({
                treeData: this.state.treeData,
                expanded,
            }),
        });
    };

    handleFormImport = (type, title) => {
        this.setState({
            typeImport: type,
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id
        });

        this.handleToggleDrawerImport('openFormImport');
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    handlerMutationImport = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.content.id,
            "locale": this.state.importLang,
            "delimiter": this.state.importSep
        };

        try {
            // todo thomas
            await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            await this.prepareTree();
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans Sinfin DXP sera exécutée lors du passage du CRON (Tâche automatique).`, 6000);
            this.handleToggleDrawerImport('openFormImports');
            this.handleToggleDrawerImport('openFormImport');
            this.props.stopLoading();
        } catch (e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    render() {
        const { treeData } = this.state;
        return (
            <div>
                <TopPanel
                    icomoon="picto-categorie"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Gérer les catégories de contenu"
                    subtitle="Veuillez compléter les champs ci-dessous pour créer votre catégorie"
                    handlerAdd={this.addCategory}
                    textAdd={"Créer"}
                    handlerImport={() => this.handleToggleDrawerImport('openFormImports')}
                    textImport={"Importer"}
                    searchHandler={false}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={treeData ? true : false}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    locales={this.props.locales}
                    hasBorder={true}
                />
                <Grid container direction="column" justifyContent="center" spacing={0} style={{ width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", marginTop: 8, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12}>
                            {treeData
                                ? <TreeView
                                    typeOfTree={'categorie'}
                                    dataTree={treeData}
                                    duplicateCat={this.duplicateCat}
                                    editCat={this.editCat}
                                    addSubcategory={this.addSubcategory}
                                    expand={this.expand}
                                    onChange={treeData => this.setState({ treeData })}
                                    canModify={true}
                                    canAdd={true}
                                    canDelete={true}
                                    deleteCat={this.handleToggleDialog}
                                />
                                : <PageLoader />
                            }
                        </Grid>
                    </Grid>
                </Grid>

                {this.state.openForm ?
                    <LayoutBuilder
                        isSublayout={false}
                        opened={this.state.openForm}
                        forClose={this.handleToggleDrawer}
                        handlerMutation={this.handlerMutation}
                        dataLayout={this.state.editForm === 'edit'
                            ? formContentCategoriesEdit(
                                this.state.cat_id,
                                this.state.categoriesData,
                                this.state.parent,
                                this.state.inputAttributes,
                                this.state.currentLang,
                                this.state.errors,
                                this.state.seeErrors,
                                this.handleMediaPicker,
                                false,
                                this.state.mediaCategories.edges,
                                this.state.selectedMediaCategories)
                            : formContentCategoriesAdd(
                                this.state.categoriesData,
                                this.state.parent,
                                this.state.inputAttributes,
                                this.state.currentLang,
                                this.state.errors,
                                this.state.seeErrors,
                                this.handleMediaPicker,
                                false,
                                this.state.mediaCategories.edges,
                                this.state.selectedMediaCategories)
                        }
                        icomoon={this.state.editForm === 'edit' ? 'ico-modifier-categorie' : 'ico-ajouter-categorie'}
                        allState={this.state}
                        stateCallback={this.handleInputChange}
                        errorCallback={this.handleFormError}
                        // deleteMutation={this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                        // deleteText={this.state.editForm === 'edit' ? 'Supprimer la catégorie contenu' : null}
                        // deleteButton={this.state.editForm}
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                    /> :
                    null
                }

                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openFormImports}
                    forClose={() => this.handleToggleDrawerImport('openFormImports')}
                    dataLayout={importCategoriesTypesConfig}
                    drawerWidth={this.props.drawerWidth}
                    dataCard={[
                        {
                            'libelle': this.props.t('products.categories.importation.flux'),
                            'bicoloreText': 'flux',
                            'colorhover': '#0273A5',
                            'picto': importFlux,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import catégories fadadaacilité en renseignant simplement votre flux',
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport('flux')
                        },
                        {
                            'libelle': 'Importer via une',
                            'bicoloreText': 'API',
                            'colorhover': '#0273A5',
                            'picto': importAPI,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import catégories facilité en renseignant simplement votre API',
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport('api')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier',
                            'colorhover': '#0273A5',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import catégories facilité en important simplement votre fichier',
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport('fichier')
                        }
                    ]}
                />

                <LayoutBuilder
                    isSublayout={true}
                    opened={this.state.openFormImport}
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawerImport('openFormImport')}
                    dataLayout={importCategoriesConfig}
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutationImport}
                    allState={this.state}
                    stateCallback={this.stateCallback}
                    stepperButtonDisabled={[() => this.state.headers === null, null]}
                    stepperButtonAction={[null, null]}
                    backStepperButtonAction={[null, null, null]}
                />

                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer cette catégorie contenu ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez cette catégorie celle-ci ne sera plus accessible. Si vous ne souhaitez pas la supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    getMediasCategories = () => {
        this.props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache',
        }).then(result => {
            this.setState({ mediaCategories: result.data.mediaObjectCategories });
        })
    }

    prepareTree() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_CONTENT_CATEGORIES_ONLY,
                fetchPolicy: 'no-cache'
            }).then(result => {
                let cats = result.data.contentCategories;
                let data = cats.filter(e => e.parent === null && e.libelle === 'Root');

                this.setState({ categoriesData: cats });
                this.setState({ inputAttributes: this.props.attributes.category.attributes });

                for (let parent of data) {
                    this.convertToNode(parent, true);
                    this.populateChildren(cats, parent);
                }

                this.setState({ 
                    treeData: toggleExpandedForAll({
                        treeData: this.copyArrayOfObjects(data)[0].children,
                        expanded: false
                    }),
                });
                resolve();
            });
        });

    }

    convertToNode(data, isRoot = false) {
        let allNames = data.contentCategoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

        let getTraduction = allNames.find(
            translation => translation.node.locale.code === this.state.currentLang
        );

        data.title = data.libelle === 'Root' ? '/' : getTraduction?.node?.value ?? allNames[0]?.node.value ?? data.libelle;
        data.isDirectory = true;
        data.isRoot = isRoot;
        data.expanded = true;
        data.attributes = [];

        for (let { node } of data.contentCategoryDatas.edges) {
            let found = false;

            for (let attribute of data.attributes) {
                if (attribute.id === node.attribute.id) {
                    found = true;

                    // add locale to existing attribute
                    attribute.locales.push({
                        value: node.value,
                        media: node.media,
                        id: node.locale.id,
                        code: node.locale.code,
                        categoryDataId: node.id // category data id
                    });
                }
            }

            if (!found) {
                // create attribute and add locale
                data.attributes.push({
                    id: node.attribute.id,
                    identifier: node.attribute.identifier,
                    attributeType: node.attribute.attributeType,
                    locales: [{
                        value: node.value,
                        media: node.media,
                        id: node.locale.id,
                        code: node.locale.code,
                        categoryDataId: node.id
                    }]
                });
            }
        }
    }

    populateChildren(cats, parent) {
        parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);

        for (let child of parent.children) {
            this.convertToNode(child);
            this.populateChildren(cats, child);
        }
    }

    copyArrayOfObjects = array => array.map(a => ({ ...a })); // be careful, only breaks references at objects level

    goTo = route => this.props.history.push(route);
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContentsCategories))));
