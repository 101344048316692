import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import Typography from '../../../ui/typography/Typography';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import {ROUTE_SALES_ESHOP, ROUTE_HOME} from '../../../../js/constants/route-names';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import CardAssets from '../../../layouts/Card/cardContent/CardAssets';

import { BUILDER, BUILDER_DASHBOARD, BUILDER_ASSETS, CREATE, VIEW } from '../../../../js/constants/constant-rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';
import { withTranslation } from 'react-i18next';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
class DashboardEngine extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            assets: [],
            currentLang: props.locales[0].node.code,
            sliderReady: false
        };
    }

    goTo = (route, type, idCat) => {

        this.props.history.push({
            pathname : route,
            state: {
                typeAsset : type,
                idCategorie : idCat
            }
        });
    };

    componentDidMount(){
        this.initDashboard();
    }

    calcTotal= (list) => {
        let counter = null;
        _.reduce(list, function(sum, n) {
            counter = sum + n;
            return counter;
        }, 0);
        return counter;
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/builder`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        assets: data.datas,
                    },() => this.initSlider());
                }
            }
        );
    }

    initSlider = () => {
        const sliderData = []
        for (let assetCat of this.state.assets.assetCategories){
            let title = '';
            let text = '';
            let image = '';
            let textButton = '';
            switch(assetCat.identifier){
                case 'cms':
                    title = this.props.t("spread_builder.dashboard.whyCreateCMS");
                    text = 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.';
                    image = Image1;
                break;
                case 'connector':
                    title = this.props.t("spread_builder.dashboard.whyCreateConnector");
                    text = 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.';
                    image = Image2;
                break;
                case 'instore':
                    title = this.props.t("spread_builder.dashboard.whyCreateInstore");
                    text = 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.';
                    image = Image1;
                break;
                case 'marketplace':
                    title = this.props.t("spread_builder.dashboard.whyCreateMarket");
                    text = 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.';
                    image = Image2;
                break;
                case 'online':
                    title = this.props.t("spread_builder.dashboard.whyCreateOnline");
                    text = 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.';
                    image = Image2;
                break;
                case 'social':
                    title = this.props.t("spread_builder.dashboard.whyCreateSocial");
                    text = 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.';
                    image = Image2;
                    textButton = 'Créer un asset Réseau Social'
                break;
                case 'webshop':
                    title = this.props.t("spread_builder.dashboard.whyCreateOnlineStore");
                    text = 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.';
                    image = Image2;
                break;
                default:
                    return null;
            }
            sliderData.push({
                title,
                text,
                image,
                id: uuidv4(),
                buttons: [
                    {
                        text: textButton ? textButton : this.props.t("spread_builder.dashboard.createAnAsset", {libelle: assetCat.libelle}),
                        action: () => this.goTo(ROUTE_SALES_ESHOP, assetCat.identifier, `/api/asset-categories/${assetCat.id}`),
                    },
                ]
            })
        }
        this.setState({
            sliderData,
            sliderReady : true
        })
    }

    render() {
        let stats = [
                {
                    'icon': 'icon-asset',
                    'data': this.state.assets.nbAssetEnable,
                    'dataColor': colors.black.regular,   
                    'subtitle': this.props.t("spread_builder.dashboard.activeAsset"),
                },
                {
                    'icon': 'icon-asset',
                    'data': this.state.assets.nbAssetDisable,
                    'dataColor': colors.red.darker,
                    'subtitle': this.props.t("spread_builder.dashboard.inactiveAsset"),
                },
        ];



        let lastDatas = [
            {
                'icon': 'icon-asset',
                'data': this.calcTotal(this.state.assets.assetsByTypes?.cms),
                'dataColor': colors.black.regular,
                'subtitle': 'Asset(s) CMS',
            },
            {
                'icon': 'icon-asset',
                'data': this.calcTotal(this.state.assets.assetsByTypes?.connector),
                'dataColor': colors.black.regular,
                'subtitle': 'Asset(s) Connector',
            },
            {
                'icon': 'icon-asset',
                'data': this.calcTotal(this.state.assets.assetsByTypes?.instore),
                'dataColor': colors.black.regular,
                'subtitle': 'Asset(s) Instore',
            },
            {
                'icon': 'icon-asset',
                'data': this.calcTotal(this.state.assets.assetsByTypes?.marketplace),
                'dataColor': colors.black.regular,
                'subtitle': 'Asset(s) Marketplace',
            },
            {
                'icon': 'icon-asset',
                'data': this.calcTotal(this.state.assets.assetsByTypes?.online),
                'dataColor': colors.black.regular,
                'subtitle': 'Asset(s) Online',
            },
            {
                'icon': 'icon-asset',
                'data': this.calcTotal(this.state.assets.assetsByTypes?.social),
                'dataColor': colors.black.regular,
                'subtitle': 'Asset(s) Social',
            },
            {
                'icon': 'icon-asset',
                'data': this.calcTotal(this.state.assets.assetsByTypes?.webshop),
                'dataColor': colors.black.regular,
                'subtitle': this.props.t("spread_builder.dashboard.assetOnlineStore"),
            },
        ];

        return (
            <div style={{width: "100%"}}>
                <TopPanel 
                    icomoon="icon-dashboard" 
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("spread_builder.dashboard.welcomeOnDashboard")}
                    strongText="Sinfin DXP Builder"
                    subtitle="Petite phrase de description OU heure de Mise à jour" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    hasBorder={true}
                    stats={stats}
                />
                <Grid container direction="column" justifyContent="center" spacing={0} style={{marginTop: 24}}>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("spread_builder.dashboard.lastDatas")}</Typography>
                        <Grid container direction="row" style={{marginTop: 8}} spacing={2}>
                            {
                                lastDatas.map((data, index) => {
                                    return(
                                        <Grid key={`LastDatas${index}`} style={{marginRight: 16, marginBottom: 16}}>
                                            <CardCustom>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Box mr={2}>
                                                                <IcomoonReact iconSet={iconSet} color="#0273A5" size={25} icon={data.icon} />
                                                            </Box>
                                                            <Typography variant="h4">{data.subtitle}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Box ml={2}>
                                                        <Grid container justifyContent="flex-end">
                                                            {
                                                                data.data || data.data === 0 ?
                                                                    <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: 23}}>{data.data}</Typography>
                                                                : <CircularProgress size={"18px"} color={"inherit"}/>
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    this.state.sliderData?.length > 0 ?
                        <Grid item xl={this.state.sliderReady && this.state.sliderData.length > 1 ? 12 : 6} lg={this.state.sliderReady && this.state.sliderData.length > 1 ? 12 : 8} md={this.state.sliderReady && this.state.sliderData.length > 1 ? 12 : 8} xs={12} style={{marginTop: 12}}>
                            <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("spread_builder.dashboard.actionsSlider")}</Typography>
                            {
                                this.state.sliderReady ?
                                    <Grid container direction="row" style={{marginTop: 8}}>
                                        <CardCustom contentpadding="16px 0 0 0">
                                            <SliderDashboard sliderData={this.state.sliderData} windowWidth={this.props.windowWidth}/>
                                        </CardCustom>
                                    </Grid>
                                : <PageLoader />
                            }
                        </Grid>
                    : null
                }
                <Grid container direction="column" justifyContent="center" spacing={0} style={{marginTop: 24}}>
                    {
                        this.state.assets.activeAssets?.length > 0 ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{this.props.t("spread_builder.dashboard.activeAsset")}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.assets.activeAssets.map((asset, index) => {
                                            let type = {
                                                node: {
                                                    ...asset,
                                                    assetType: {
                                                        libelle: asset.asset.assetTypeLibelle,
                                                        identifier: asset.asset.assetTypeIdentifier,
                                                        assetCategory : {
                                                            identifier: asset.asset.assetCategoryIdentifier
                                                        }
                                                    }
                                                }
                                            }
                                            if (asset.asset.assetGameTypeIdentifier)(
                                                type.node.assetGameType = {
                                                    identifier: asset.asset.assetGameTypeIdentifier,
                                                    libelle: asset.asset.assetGameTypeLibelle,
                                                }
                                            )
                                            return(
                                                <GridCustom item lg={3} md={4} xs={6} key={`ListProduct${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardAssets 
                                                        asset={type} 
                                                        inputCard={true} 
                                                        image={asset.categoryLogo} 
                                                        isDashboard={true}
                                                        currentLang={this.state.currentLang} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardEngine))));