import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid, InputLabel } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import EmptyUsers from '../../../../assets/pictos/empty-picto/user.svg';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import CardCustom from '../../../layouts/Card/CardCustom';
import CardUsers from '../../../layouts/Card/cardContent/CardUsers';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import InputBuilder from '../../../ui/form/InputBuilder';
import Button from '../../../ui/button/Button';
import { ROUTE_CRM_SETTINGS_DETAIL_USER, ROUTE_HOME } from '../../../../js/constants/route-names';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import addUserForm from './config/addUser.config';
import editUserForm from './config/editUser.config';
import * as moment from "moment";
import { withApollo } from 'react-apollo';
import { GET_USERS, ADD_USER } from '../../../../queries/users';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import * as generator from 'generate-password';
import request from '../../../../js/utils/fetch';
import { default as newAccountTemplate } from '../../../../email/newAccount';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_SETTINGS, CRM_SETTINGS_USERS, VIEW, CREATE } from '../../../../js/constants/constant-rights';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1450px){
        max-width: 50%;
        flex-basis: 50%;
    }
`;

const SpanColor = styled.span`
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;

const emailPopup = {
    type: 'text',
    label: 'Email',
    helper: 'Langue',
    disabled: 'disabled',
    stateName: 'email',
    value: ''
};
const passwordPopup = {
    type: 'password',
    label: 'Mot de passe',
    helper: 'Langue',
    disabled: 'disabled',
    stateName: 'password',
    value: ''
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SettingsUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openSnack: false,
            openForm: false,
            editForm: 'add',
            editPassword: false,
            openDialogPassword: false,
            page: 1,
            pageOfItems: [],
            user_id: '',
            user_token_id: '',
            email: '',
            plainPassword: '',
            plainPasswordRepeat: '',
            roles: 'user',
            firstname: '',
            lastname: '',
            phone: '',
            image: '',
            preferredLangcode: 'fr',
            isActive: true,
            notif: false,
            generate: false,
            isBlocked: false,
            showPassword: false,
            createdAt: moment().format(),
            updatedAt: moment().format(),
            inputHidden: [],
            filtered: [],
            errors: {}
        }
        this.stats =
            [
                {
                    'icon': 'picto-stock',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.blue.darker.hue300,
                    'subtitle': 'Mise à jour des Stocks',
                    'gradientColor1': colors.menu.regular,
                    'gradientColor2': colors.menu.darker,
                },
                {
                    'icon': 'picto-produit',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.blue.darker.hue300,
                    'subtitle': 'Mise à jour des Produits',
                    'gradientColor1': colors.menu.regular,
                    'gradientColor2': colors.menu.darker,
                },
                {
                    'icon': 'picto-prix',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.blue.darker.hue300,
                    'subtitle': 'Mise à jour des Prix',
                    'gradientColor1': colors.menu.regular,
                    'gradientColor2': colors.menu.darker,
                },
            ];
        this.handleChange = this.handleChange.bind(this);
    }

    handleGetUsers() {
        const getUsers = GET_USERS;
        this.props.client.query({
            query: getUsers,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.users = result.data.users.edges;
            this.setState({
                users: result.data.users.edges,
                filtered: this.users
            });
        });
    }

    handleChange(e) {
        let currentList = [];
        let newList = [];
        let newList2 = [];
        let newList3 = [];
        let newList4 = [];
        let newList5 = [];
        let newList6 = [];
        let result = [];

        // SearchBar not empty ?
        if (e.target.value !== "") {
            // Assign the original list to currentList
            currentList = this.users;
            // Use .filter() to determine which items should be displayed
            // based on the search terms
            newList = currentList.filter(item => {
                //FIRSTNAME
                const lc = item.node.firstname.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
            newList2 = currentList.filter(item => {
                //LASTNAME
                const lc = item.node.lastname.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
            newList3 = currentList.filter(item => {
                //LASTNAME FIRSTNAME
                const lc = item.node.lastname.toLowerCase() + ' ' + item.node.firstname.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
            newList4 = currentList.filter(item => {
                // FIRSTNAME LASTNAME
                const lc = item.node.firstname.toLowerCase() + ' ' + item.node.lastname.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
            newList5 = currentList.filter(item => {
                // EMAIL
                const lc = item.node.email.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
            newList6 = currentList.filter(item => {
                // PHONE
                const lc = item.node.phone.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });

            result = result.concat(newList);
            result = result.concat(newList2);
            result = result.concat(newList3);
            result = result.concat(newList4);
            result = result.concat(newList5);
            result = result.concat(newList6);

            let j = 0;
            for (let item of result) {
                let i = 0;
                for (let item2 of result) {
                    if (item.node.id === item2.node.id && j < i) {
                        result.splice(i)
                    }
                    i++;
                }
                j++;
            }
        } else {
            // If the search bar is empty, set newList to original task list
            result = this.users;
        }
        // Set the filtered state based on what our rules added to newList
        this.setState({
            filtered: result
        });
    }

    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;

        this.setState({
            ...this.state,
            [stateName]: value
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });

        if (stateName === 'generate') {
            this.handleFormError('plainPassword', false);
            this.handleFormError('plainPasswordRepeat', false);
        }
    };

    handleToggleDialogPassword = () => {
        this.setState({
            openDialogPassword: !this.state.openDialogPassword
        });
        this.handleToggleDrawer();
        this.handleGetUsers();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }

        if (this.state.plainPassword !== this.state.plainPasswordRepeat) {
            return this.props.snack(ALERT_ERROR, 'Les mots de passe ne sont pas identiques');
        }

        this.props.startLoading();

        let query = ADD_USER;
        let variables = {
            "email": this.state.email,
            "password": this.state.plainPassword,
            "roles": [],
            "firstname": this.state.firstname,
            "lastname": this.state.lastname,
            "phone": this.state.phone,
            "preferredLangcode": this.state.preferredLangcode,
            "isActive": this.state.isActive,
            "isBlocked": this.state.isBlocked,
            "createdAt": this.state.createdAt,
            "updatedAt": this.state.updatedAt,
            "image": this.state.image.data
        }
        this.props.client.mutate({
            mutation: query,
            variables,
            refetchQueries: [{
                query: GET_USERS,
            }]
        }).then(result => {
            if (this.state.editForm === 'add' || this.state.editPassword) {
                this.setState({ openDialogPassword: true });
                this.props.snack(ALERT_SUCCESS, 'Le compte est ajouté');
                request(`${process.env.REACT_APP_API}/sender-emails`, 'post', {
                    sender: "no-reply@sinfin.fr",
                    receiver: this.state.email,
                    subject: "Nouveau compte Sinfin DXP",
                    content: newAccountTemplate(this.state.email, this.state.plainPassword, `${process.env.REACT_APP_PUBLIC}/login`, `${process.env.REACT_APP_PUBLIC}/logo192.png`, this.state.notif)
                });
            }
            this.props.stopLoading();
        })
    }

    resetState() {
        this.setState({
            editForm: 'add',
            email: '',
            plainPassword: '',
            roles: 'user',
            firstname: '',
            lastname: '',
            phone: '',
            image: {
                changed: false,
                data: null
            },
            preferredLangcode: 'fr',
            isActive: true,
            notif: false,
            generate: false,
            isBlocked: false,
            createdAt: moment().format(),
            updatedAt: moment().format(),
            editPassword: true,
            hideInput: [],
            errors: {}
        });
        this.handleGetUsers();
    }

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm
        }, () => {
            if (!this.state.openForm) {
                this.resetState();
            }
        });

    };

    componentDidMount() {
        const getRights = hasRights(CRM_SETTINGS, CRM_SETTINGS_USERS, VIEW)
        if (!getRights) {
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.handleGetUsers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.user_id !== prevState.user_id) {
            if (this.state.user_id === this.props.user.id) {
                let i = 0;
                for (let input of editUserForm.formConfig.children[0].optionsInputs) {
                    if (input.stateName === "isActive") {
                        input.disabled = true;
                        editUserForm.formConfig.children[0].optionsInputs[i] = input;
                    }
                    i++;
                }
            }
            else {
                for (let input of editUserForm.formConfig.children[0].optionsInputs) {
                    if (input.stateName === "isActive") {
                        input.disabled = false;
                        editUserForm.formConfig.children[0].optionsInputs[i] = input;
                    }
                    i++;
                }
            }
        }
        if (this.state.isActive !== prevState.isActive) {
            if (this.state.isActive) {
                this.setState({
                    isBlocked: false,
                })
            } else if (!this.state.isActive) {
                this.setState({
                    isBlocked: true,
                })
            }
        }
        if (this.state.generate !== prevState.generate) {
            if (this.state.generate) {
                var password = generator.generate({
                    length: 12,
                    numbers: true,
                    symbols: true,
                    lowercase: true,
                    uppercase: true,
                    excludeSimilarCharacters: true,
                    exclude: '"\'{}[]()=:;.,_+-?/<>',
                    strict: true
                });
                this.setState({
                    plainPassword: password,
                    plainPasswordRepeat: password,
                })
            }
            else {
                this.setState({
                    plainPassword: '',
                    plainPasswordRepeat: ''
                })
            }
            if (this.state.generate) {
                this.state.inputHidden.push('plainPassword', 'plainPasswordRepeat')
            } else {
                for (var i = 0; i < this.state.inputHidden.length; i++) {
                    if (this.state.inputHidden[i] === 'plainPassword') {
                        this.state.inputHidden.splice(i, 1);
                    }
                    if (this.state.inputHidden[i] === 'plainPasswordRepeat') {
                        this.state.inputHidden.splice(i, 1);
                    }
                }
            }
        }
        if (this.state.editPassword !== prevState.editPassword) {
            if (this.state.editPassword) {
                this.setState({
                    inputHidden: []
                })
            }
            else {
                this.setState({
                    plainPassword: '',
                    plainPasswordRepeat: '',
                    inputHidden: ['plainPassword', 'plainPasswordRepeat', 'generate', 'notif']
                });
            }
        }
    }

    render() {
        return (
            <div>
                <TopPanel
                    icomoon="picto-profil"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Gestion des utilisateurs"
                    subtitle="Gestion de vos utilisateurs (création / modification / suppression)"
                    handlerAdd={this.handleToggleDrawer}
                    textAdd={hasRights(CRM_SETTINGS, CRM_SETTINGS_USERS, CREATE) ? "Ajouter un utilisateur" : null}
                    searchHandler={this.handleChange}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    // stats={this.stats} 
                    windowWidth={this.props.windowWidth}
                    buttonAvailable={!!this.state.users}
                    openForm={this.state.openForm}
                    hasBorder={true}
                />
                <Grid container direction="column" justifyContent="center" spacing={0} style={{ paddingBottom: 24, marginTop: 24 }}>
                    <Grid container direction="row" spacing={4} style={{ width: this.state.openForm && this.state.filtered.length === 0 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                        {this.state.filtered.length > 0 ?
                            this.state.filtered.map((user, i) =>
                                <GridCustom item lg={4} md={6} xs={12} key={`CardUser${i}`} /*style={Math.ceil((i+1)/9) === this.state.page ? {display: "block"} : {display: "none"}}*/>
                                    <CardCustom paddingbottom="0px" style={{ width: '100%' }}>
                                        <CardUsers userProperty={user.node} editUser={() => { this.goTo(ROUTE_CRM_SETTINGS_DETAIL_USER, user.node.id) }} />
                                    </CardCustom>
                                </GridCustom>
                            )
                            : this.state.users ?
                                (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={hasRights(CRM_SETTINGS, CRM_SETTINGS_USERS, CREATE) ? "Ajouter un utilisateur" : null} onClick={hasRights(CRM_SETTINGS, CRM_SETTINGS_USERS, CREATE) ? () => { this.handleToggleDrawer() } : null} picto={EmptyUsers} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                                :
                                (<Typography variant="h3" style={{ marginTop: 32, textAlign: 'center', width: '100%' }}>Chargement en cours...</Typography>)
                        }
                    </Grid>
                </Grid>
                <LayoutBuilder
                    icomoon={"ico-ajouter-utilisateur"}
                    opened={this.state.openForm}
                    forClose={this.handleToggleDrawer}
                    dataLayout={addUserForm}
                    allState={this.state}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    handleButtonGroupChange={this.handleButtonGroupChange}
                    handlerMutation={this.handlerMutation}
                    hideInput={this.state.inputHidden}
                    validateButton={true}
                />
                <Dialog
                    open={this.state.openDialogPassword}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.editForm === 'add' ? ('Nouveau compte ajouté !') : ('Compte modifié !')}</DialogTitle>
                    <DialogContent style={{ width: 500 }}>
                        <DialogContentText id="alert-dialog-description">
                            <SpanColor>{this.state.firstname} {this.state.lastname}</SpanColor> {this.state.editForm === 'add' ? ('peut désormais commencer à utiliser Sinfin DXP.') : ('a désormais des nouveaux identifiants Sinfin DXP.')}
                        </DialogContentText>
                        <DialogContentText style={{ paddingTop: 10 }}>
                            <Grid container>
                                <GridFlexCenter item xs={4}>
                                    <InputLabelCustom>Email</InputLabelCustom>
                                </GridFlexCenter>
                                <InputBuilder input={emailPopup} xs={8} value={this.state.email} />
                            </Grid>
                        </DialogContentText>
                        <DialogContentText style={{ marginBottom: 0 }}>
                            <Grid container>
                                <GridFlexCenter item xs={4}>
                                    <InputLabelCustom>Mot de passe</InputLabelCustom>
                                </GridFlexCenter>
                                <InputBuilder input={passwordPopup} xs={8} value={this.state.plainPassword} />
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialogPassword}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={this.state.openSnack} autoHideDuration={3000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={'success'}>
                        Le compte a bien été mis à jour !
                    </Alert>
                </Snackbar>
            </div>
        );
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { userId: id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(SettingsUsers)));